import React, { useState } from 'react';

interface DocumentInputProps {
    disabled?: boolean;
    onChange?: (field: string, value: string) => void;
}
const DocumentInput: React.FC<DocumentInputProps> = ({ disabled = false, onChange }) => {
    const [documentNumber, setDocumentNumber] = useState('');
    const [identifier, setIdentifier] = useState('');
    const [error, setError] = useState('');

    // Regex for CPF, CNPJ, and RNM
    const CPF_REGEX = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
    const CNPJ_REGEX = /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/;
    const RNM_REGEX = /^\d{8,11}$/;

    // Formatter functions
    const formatCPF = (value: string) => {
        return value
            .replace(/\D/g, '')
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d{2})$/, '$1-$2');
    };

    const formatCNPJ = (value: string) => {
        return value
            .replace(/\D/g, '')
            .replace(/(\d{2})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1/$2')
            .replace(/(\d{4})(\d{2})$/, '$1-$2');
    };

    // Main handler for input changes
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value;
        setError('');

        // Determine document type and apply formatting
        if (/^\d{11}$/.test(value.replace(/\D/g, ''))) {
            setIdentifier('CPF');
            value = formatCPF(value);
        } else if (/^\d{14}$/.test(value.replace(/\D/g, ''))) {
            setIdentifier('CNPJ');
            value = formatCNPJ(value);
        } else if (/^\d{8,11}$/.test(value.replace(/\D/g, ''))) {
            setIdentifier('RNM');
        } else {
            setIdentifier('');
        }

        setDocumentNumber(value);
        if(onChange){
            onChange('instructorDoc', value);
        }
    };

    // Function to validate CPF/CNPJ/RNM
    const validateDocument = () => {
        const strippedValue = documentNumber.replace(/\D/g, '');

        if (CPF_REGEX.test(documentNumber) && validateCPF(strippedValue)) {
            setError('');
        } else if (CNPJ_REGEX.test(documentNumber) && validateCNPJ(strippedValue)) {
            setError('');
        } else if (RNM_REGEX.test(documentNumber)) {
            setError('');
        } else {
            setError('Invalid document number');
        }
    };

    // CPF validation function
    const validateCPF = (cpf: string) => {
        if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) return false;

        let sum = 0;
        for (let i = 0; i < 9; i++) sum += parseInt(cpf.charAt(i)) * (10 - i);
        let firstCheckDigit = (sum * 10) % 11;
        if (firstCheckDigit === 10) firstCheckDigit = 0;
        if (firstCheckDigit !== parseInt(cpf.charAt(9))) return false;

        sum = 0;
        for (let i = 0; i < 10; i++) sum += parseInt(cpf.charAt(i)) * (11 - i);
        let secondCheckDigit = (sum * 10) % 11;
        if (secondCheckDigit === 10) secondCheckDigit = 0;

        return secondCheckDigit === parseInt(cpf.charAt(10));
    };

    // CNPJ validation function
    const validateCNPJ = (cnpj: string) => {
        if (cnpj.length !== 14 || /^(\d)\1+$/.test(cnpj)) return false;

        const calcCheckDigit = (cnpj: string, weight: number[]) => {
            let sum = 0;
            for (let i = 0; i < weight.length; i++) sum += parseInt(cnpj.charAt(i)) * weight[i];
            return (sum % 11) < 2 ? 0 : 11 - (sum % 11);
        };

        const firstCheckDigit = calcCheckDigit(cnpj, [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]);
        const secondCheckDigit = calcCheckDigit(cnpj, [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3]);

        return (
            firstCheckDigit === parseInt(cnpj.charAt(12)) &&
            secondCheckDigit === parseInt(cnpj.charAt(13))
        );
    };

    return (
        <div className="document-input">
            <input
                id="document-input" 
                className='text line'
                type="text"
                disabled={disabled}
                value={documentNumber}
                onChange={handleChange}
                onBlur={validateDocument}
                placeholder="Insira seu CPF, CNPJ ou RNM"
            />
            <p className="identifier">{identifier || ''}</p>
        </div>
    );
};

export default DocumentInput;
