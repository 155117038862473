import "./style.scss";
import Lottie from "lottie-react";
import loadingAnimation from "../../../misc/animations/loadingAnimation.json";

export function Loading() {
  return (
    <div className="loading-container">
      <div className="loading">
        <h1>Deixe o mar guiar enquanto buscamos sua próxima aventura!</h1>
        <Lottie animationData={loadingAnimation} height={20} width={20} />
      </div>
    </div>
  );
}
