import "./style.scss"
import { Link } from "react-router-dom"

const NotFound =()=>{
    return(
        <div className="container">
            <h2>Erro 404 <p/> Página não encontrada</h2>
            
            <Link to='/' className='turnback'>Voltar para página inicial</Link>
        </div>
    )
}
export default NotFound