import { useState } from 'react';
import { getFirestore, doc, setDoc } from 'firebase/firestore';
import { useAuth } from '../../services/firebase/authContext';

const AnnouncementForm = () => {
    /**Objeto de anúncio**
    Todos os anúncios são setados com todas as variáveis, que podem ser nulas de acordo com o tipo selecionado
    Os anúncios quesitos marcados como geral serão utilizados por todas as classes e modalidades de anúncio
    Em seguida virão os específicos
    */
    const [formData, setFormData] = useState({
        // General
        title: '',
        description: '',
        imageList: [],
        type: '',
        address: '',
        city: '',
        state: '',
        phoneNumber: '',
        politics: '',
        accessibility: false,
        liveVest: false,
        firstAid: false,
        unitValue: 0,
        adults: 0,
        kids: 0,
        date: '',
        
        // Boats
        captainName: '',
        captainContact: '',
        capitainDoc: '',
        habType: '',
        habNumber: '',
        boatName: '',
        model: '',
        manufacturer: '',
        year: '',
        feet: 0,
        bathrooms: 0,
        crew: 1,
        cabins: 0,
        powerType: false,
        EnginePower: 0,
        overNight: false,
        tourMode: '',

        // Sports
        experienceType: '',
        prerequisite: '',
        classType: '',
        minClasses: 0,
        instructorName: '',
        instructorAreaNumber: '',
        instructorNumber: '',
        instructorDoc: '',   
        certification: '',
    });

    const handleInputChange = (key: string, value: any) => {
        setFormData((prevState) => ({
            ...prevState,
            [key]: value,
        }));
    };

    const { userEmail } = useAuth(); // Get user email from authentication context
    const db = getFirestore(); // Initialize Firestore instance

    const saveToFirestore = async () => {
        if (!userEmail) {
            console.error('Usuário não autenticado. Não é possível salvar o anúncio.');
            return;
        }

        try {
            // Create a new document in Firestore
            const announcementRef = doc(
                db,
                'users',
                userEmail,
                'announcements',
                `announce-${Date.now()}` // Unique ID for each announcement
            );
            await setDoc(announcementRef, formData);

            console.log('Anúncio salvo com sucesso!');
        } catch (error) {
            console.error('Erro ao salvar anúncio no Firestore:', error);
        }
    };

    return { formData, handleInputChange, saveToFirestore };
};

export default AnnouncementForm;
