import React from 'react';
import './style.scss'
interface ConfirmPopupProps {
    isVisible: boolean;
    onConfirm: () => void;
    onCancel: () => void;
}

const ConfirmPopup: React.FC<ConfirmPopupProps> = ({ isVisible, onConfirm, onCancel }) => {
    if (!isVisible) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h3>Resetar informações?</h3>
                <p>Ao continuar, todas as informações voltarão ao padrão do usuário.</p>
                <p>Tem certeza que deseja continuar?</p>
                <div className="modal-actions">
                    <button id="confirm" onClick={onConfirm}>Sim, resetar</button>
                    <button id="cancel"  onClick={onCancel}>Cancelar</button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmPopup;
