import './style.scss';
import React, { useState, useEffect } from 'react';
import { useAuth } from '../../services/firebase/authContext';
import { getAnnouncementsFromFirestore, addAnnouncementToFirestore, deleteAnnouncementFromFirestore } from '../../services/firebase/announcementService';
import AnnouncementForm from './announceForm';
import NewAnnounce from "./newAnnounce";

const AnnouncementPage: React.FC = () => {
    const { formData, handleInputChange } = AnnouncementForm();
    const [announcements, setAnnouncements] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
    const [newForm, setNewForm] = useState(false);
    const [noAnnouncement, setNoAnnouncement] = useState(true);
    const { userEmail } = useAuth();

    // Função para criação de novo anúncio
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            if (!userEmail) throw new Error('Usuário não autenticado');

            await addAnnouncementToFirestore(formData, userEmail);
            console.log('Anúncio criado com sucesso!');
            setNewForm(false); // Close the form after submission
            setNoAnnouncement(false); // Show announcements list
        } catch (error) {
            console.error('Erro ao criar anúncio: ', error);
        }
    };

    // Função para deletar um anúncio
    const handleDeleteAnnouncement = async (announcementId: string) => {
        try {
            await deleteAnnouncementFromFirestore(announcementId);
            setAnnouncements(prevAnnouncements =>
                prevAnnouncements.filter(announcement => announcement.id !== announcementId)
            );
        } catch (error) {
            console.error('Erro ao remover anúncio:', error);
        }
    };

    // Carregar anúncios existentes do Firestore
    useEffect(() => {
        const loadAnnouncements = async () => {
            setLoading(true);
            try {
                const fetchedAnnouncements = await getAnnouncementsFromFirestore();
                setAnnouncements(fetchedAnnouncements);
                setLoading(false);
            } catch (error) {
                console.error('Erro ao buscar anúncios: ', error);
                setLoading(false);
            }
        };
        loadAnnouncements();
    }, []);

    // Função para abrir o formulário de novo anúncio
    const handleNewAnnouncement = () => {
        setNewForm(true);
        setNoAnnouncement(false);
    };

    return (
        <div className="announcement-pages">
            {loading ? (
                <div className="loading">
                    <p>Carregando . . .</p>
                    <figure />
                </div>
            ) : newForm ? (
                <NewAnnounce 
                    handleSubmit={handleSubmit}
                    formData={formData}
                    handleInputChange={handleInputChange} 
                />
            ) : announcements.length === 0 ? (
                <div className="no-announcements">
                    <div className="content">
                        <h2 className="title element">Nenhum anúncio criado</h2>
                        <div className="advice">
                            <span  className="content-element">Anuncie sua experiência</span>
                                <button className="event-button element"onClick={handleNewAnnouncement}/>
                        </div>
                    </div>
                </div>
            ) : (
                announcements.map((announcement) => (
                    <div key={announcement.id} className="announce">
                        <div className="announcement-item">
                            <h3>{announcement.title}</h3>
                            <p>{announcement.description}</p>
                            <p>Price: {announcement.price}</p>
                            <p>Location: {announcement.location}</p>
                        </div>
                        <button 
                            className="delete-button"
                            onClick={() => handleDeleteAnnouncement(announcement.id)}
                        >
                            Delete
                        </button>
                    </div>
                ))
            )}
        </div>
    );
};

export default AnnouncementPage;
