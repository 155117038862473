import "./loginPopup.scss";
import React, { useState, useEffect } from "react";
import AppleSignIn from './appleSignIn';
import GoogleSignIn from './googleSignIn';
import logo from "../../misc/logo-colorida.svg"
import { auth, db } from '../../services/firebase/firebaseConfig';
import { doc, setDoc} from "firebase/firestore"
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, sendEmailVerification, sendSignInLinkToEmail, isSignInWithEmailLink } from 'firebase/auth';
import ConfirmPopup from "../navbar/confirmPopup";
import ForgotPassword from "../forgot-password/forgotPassword";

interface LoginPopupProps {
  onClose: () => void;
  setUser: (user: any) => void;
}
const LoginPopup: React.FC<LoginPopupProps> = ({ onClose, setUser }) => {
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [document, setDocument] = useState("");
  const [email, setEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [cpf, setCpf] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [showRegisterForm, setShowRegisterForm] = useState(false);
  const [category, setCategory] = useState<'Cliente' | 'Anunciante'>('Cliente');
  const [error, setError] = useState('')
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const data = db


  useEffect(() => {
    const verifyAndRegisterUser = async () => {
      if (isSignInWithEmailLink(auth, window.location.href)) {
        let email = window.localStorage.getItem('emailForSignIn');
        if (!email) {
          email = prompt('Por favor, forneça seu email para concluir o cadastro.');     
        }

        try {
          // Step 3: Register the user after email verification
          const userCredential = await createUserWithEmailAndPassword(auth, email!, password);
          console.log('Usuário registrado com sucesso:', userCredential.user);
          setUser(userCredential.user);
          setError('Cadastro concluído com sucesso! Agora você pode fazer login.');
        } catch (error: any) {
          console.error('Erro ao criar usuário:', error);
          setError(error.message);
        }
      }
    };

    verifyAndRegisterUser();
  }, []);

  // Redefinição de senha
  const handleForgotPassword = (email: string) => {
    // Logic for password reset (e.g., send a reset link via Firebase)
    console.log(`Reset link sent to: ${email}`);
  };

  //envio de email de confirmação
  const handleSendEmail = async () => {
    if (auth.currentUser) {
      try {
        await sendEmailVerification(auth.currentUser);
        setError('Email de verificação reenviado com sucesso.');
      } catch (error: any) {
        console.error('Erro ao reenviar email de verificação:', error);
        setError(error.message);
      }
    }
  };

  // Verificações de input
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };
  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserName(e.target.value);
  };
  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };
  const handleCpfChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCpf(e.target.value);
  }; // Handler for CPF change
  const handleRememberMeChange = () => {
    setRememberMe(!rememberMe);
  };

  //Método de login manual
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (showRegisterForm) {
        // Ensure email is valid
        if (!email) {
          setError("Insira um email válido.");
          return;
        }

        // Configuração de email para envio de link de verificação
        const actionCodeSettings = {
          url: `${window.location.origin}/verify?email=${email}`,
          handleCodeInApp: true,
        };

        // Armazena o email no localStorage para checagem posterior
        window.localStorage.setItem("emailForSignIn", email);

        // Cria o usuário no Firebase Auth
        createUserWithEmailAndPassword(
          auth,
          email,
          password
        ).then((userCredential) =>{
          const user = userCredential.user;
        }).catch((error)=>{
          const errorCode = error.code;
          const errorMessage = error.message;
        })
        
        // Salva informações no Firestore com o email como ID do documento
        await setDoc(doc(data, 'users',email), {
          name: userName,
          category: category,
          email
        });

        console.log("Usuário salvo no Firestore com ID:", email);
        setShowConfirmPopup(true);

      } else {
        // Login para usuários existentes
        const userCredential = await signInWithEmailAndPassword(
          auth,
          email,
          password
        );
        const user = userCredential.user;

        // Verifica se o email está verificado
        if (!user.emailVerified) {
          setError(
            "Seu email ainda não foi verificado. Verifique-o antes de fazer login."
          );
          setShowConfirmPopup(true);
          return;
        }

        console.log("Login bem-sucedido como:", user.email);
        setUser(user);
      }

      onClose(); // Fecha o popup de login/cadastro
    } catch (error: any) {
      console.error("Erro ao processar a solicitação:", error);
      setError(error.message);
    }
  };




  const handleDocumentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDocument(e.target.value);
  };

  // Toggle between login and register
  const handleSwitchToRegister = () => {
    setShowRegisterForm(true);
  };
  const handleSwitchToLogin = () => {
    setShowRegisterForm(false);
  };


  return (
    <>

      <div className="login-popup">
        <div className="logo-wrapper">
          <img src={logo} alt="Logo" width="100" height="100" />
        </div>

        {/* Toggle entre Cliente e Anunciante */}
        <div className="toggle-category">
          <button
            className={category === 'Cliente' ? 'active' : ''}
            onClick={() => setCategory('Cliente')}
          >
            Cliente
          </button>
          <button
            className={category === 'Anunciante' ? 'active' : ''}
            onClick={() => setCategory('Anunciante')}
          >
            Anunciante
          </button>
        </div>
        <p className="login-title">{showRegisterForm ? "Cadastrar" : "Login"} como {category}</p>

        {error ? <span className="error">Login ou senha inválidos</span> : <></>}
        {showRegisterForm ? (
          <div className="register-form input-wrapper">
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={handleEmailChange}
            />
            <input
              type="text"
              placeholder="Nome"
              value={userName}
              onChange={handleNameChange}
            />
            <input
              type="password"
              placeholder="Senha"
              value={password}
              onChange={handlePasswordChange}
            />
            {category === 'Anunciante' && (
              <input
                type="text"
                placeholder="CPF ou CNPJ"
                value={document}
                onChange={handleDocumentChange}
              />

            )}
          </div>
        ) : (
          <div className="login-form input-wrapper">
            <input
              type="email"
              placeholder="Usuário"
              value={email}
              onChange={handleEmailChange}
            />
            <input
              type="password"
              placeholder="Senha"
              value={password}
              onChange={handlePasswordChange}
            />
          </div>
        )}
        {showRegisterForm ?
          <ForgotPassword
            onClose={() => setShowForgotPassword(false)}
            onPasswordReset={handleForgotPassword}
          />
          :
          <div>
            <a
              className="esqueceu-senha"
              onClick={(e) => {
                e.preventDefault();
                setShowForgotPassword(true);
              }}
            >
              Esqueceu a senha?
            </a>
          </div>
        }
        <div className="toggle-category">
          <button type="submit" className="active login-icons login-button" onClick={(e) => handleSubmit(e as any)}>
            {showRegisterForm ? 'Cadastrar' : 'Login'} </button>
        </div>

        <div className="auth">
          <GoogleSignIn onClose={onClose} />
          {/* <AppleSignIn /> */}
        </div>

        <div className="register-wrapper">
          {!showRegisterForm && (
            <p>
              Ainda não tem cadastro?{" "}
              <button onClick={handleSwitchToRegister}>
                Clique aqui para fazer a sua conta
              </button>
            </p>
          )}
          {showRegisterForm && (
            <p>
              Já possui uma conta?{" "}
              <button onClick={handleSwitchToLogin}>
                Clique aqui para fazer o login
              </button>
            </p>
          )}
        </div>
      </div>
      <div className="background" onClick={onClose}></div>
      {showConfirmPopup && (
        <ConfirmPopup
          onResendEmail={handleSendEmail}
          onClose={() => setShowConfirmPopup(false)}
        />
      )}
    </>
  );
};

export default LoginPopup;