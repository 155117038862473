import "./style.scss";
import { IExperiencia } from "../../../../models/experiencia";
import BlackStar from "../../../../misc/icons/star-full-black.svg";
import { CardDestaque } from "../../../detalhes-experiencia/card-destaque/card-destaque";
import { CardContato } from "../../../../components/card-contato/card-contato";
import Placeholder from "../../../../misc/placeholder/placeholder.png";
import { useState } from "react";

interface IExperienciaProps {
  content: IExperiencia;
}

export function Experiencia(props: IExperienciaProps) {
  const response: IExperiencia = props.content;

  // Estado para controlar a imagem principal
    const [mainImage, setMainImage] = useState(response.images[0]);
    
  
    // Função para mudar a imagem principal
    const handleImageClick = (image : string) => {
      setMainImage(image);
    };

  return (
    <section className="maraberto-experiencia-section">
      <header className="light">
        <h2>{response.nome}</h2>
        <div className="info">
          <span>
            <img draggable="false" src={BlackStar} alt="" />
            <span>{response.rating}</span>
          </span>
          -<span>{`${response.quantidadeAvaliacoes} Avaliações`}</span>-
          <span>
            <b>{`${response.regiao}, ${response.cidade}`}</b>
          </span>
        </div>
      </header>
      <div className="image-gallery">
      <div className="gallery">
          {/* Mapear a galeria de imagens */}
          {response.images.slice().map((image, index) => (
            <img
              key={index}
              draggable="false"
              className="main-img"
              src={image}
              alt={`imagem-${index + 1}`}
              onClick={() => handleImageClick(image)} // Atualiza a imagem principal ao clicar
            />
          ))}
        </div>
         {/* Imagem principal que será alterada ao clicar */}
         <img
          draggable="false"
          className="main-img"
          src={mainImage}
          alt="Imagem principal"
        />
        <div className="side-container">
          <p >
            {response.description}
          </p>
        </div>
      </div>
      <div className="main-experiencia">
        <header>
        <div className="head">
          <img
            draggable="false"
            className="profile-photo"
            src={response.profilePhoto ? response.profilePhoto : ""}
            alt=""
          />
          <div className="headings">
            <h2>{response.nomeDonoExperiencia}</h2>
            <div className="capacidade">{`${response.capacidadeMaxima} Pessoas`}</div>
          </div>
        </div>

        <div className="info">
          <div className="destaques-container">
            {response.destaques.map((item) => {
              return <CardDestaque content={item} />;
            })}
          </div>
        </div>
        </header>
          <CardContato content={response} />
        </div>
        <div className="long-description">{response.longDescription}</div>
    </section>
  );
}
