import { NavLink } from "react-router-dom";
import FecharIcon from "../../../misc/icons/xis.svg";
import { useAuth } from "../../../services/firebase/authContext";
import GoogleSignIn from '../../login-pop-up/googleSignIn';
import GoogleSignOut from "../../login-pop-up/signOut";
import { useEffect, useState } from "react";
import LoginPopup from "../../login-pop-up/loginPopup";

import "./mobile-menu.scss";

interface IMobileMenuProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  setMarSocialLastActive: (value: boolean) => void;
  setMarAbertoLastActive: (value: boolean) => void;
}

export function MobileMenu(props: IMobileMenuProps) {

  const [isLoggedIn, setIsLoggedIn] = useState(() => {
      const storedIsLoggedIn = localStorage.getItem("isLoggedIn");
      return storedIsLoggedIn ? JSON.parse(storedIsLoggedIn) : false;
    });

  const fechar = () => {
    props.setOpen(false);
  };

  const marAbertoLastHandler = () => {
    props.setMarAbertoLastActive(true);
    props.setMarSocialLastActive(false);
  };
  const marSocialLastHandler = () => {
    props.setMarSocialLastActive(true);
    props.setMarAbertoLastActive(false);
  };
  //informações de confirmação de login
  const {userEmail} = useAuth();
  
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const handleLoginButtonClick = () => {
    setShowLoginPopup(true);
  };
  const handleCloseLoginPopup = () => {
    setShowLoginPopup(false);
  };

  return (
    <div className={`mobile-menu ${props.open ? "active" : "inactive "}`}>
      <div className="fechar-button" onClick={fechar}>
        <img src={FecharIcon} alt="" />
      </div>
      <ul>
      <li onClick={fechar}>
          <NavLink to="/buscar-experiencias">Experiências</NavLink>
        </li>
        <li onClick={fechar}>
          <NavLink to="/ajuda">Ajuda</NavLink>
        </li>
        <li onClick={fechar}>
          <NavLink to="/anunciante/conta">Minha conta</NavLink>
        </li>
        <li onClick={fechar}>
          <NavLink to="/anunciante/anuncios">Meus anúncios</NavLink>
        </li>
        {/* <li onClick={fechar}>
          <Link to="/experiencias">Experiências</Link>
        </li> */}
        <li>
            {userEmail? <GoogleSignOut /> : <button className="signButtom" onClick={handleLoginButtonClick}>Login</button> }
            {showLoginPopup && (<LoginPopup onClose={handleCloseLoginPopup} setUser={setIsLoggedIn} />)}
        </li>
      </ul>
    </div>
  );
}
