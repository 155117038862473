import '../footer/style.scss'
import { contact } from '../../services/data/tokens';

const phone  = contact.phone
const Whatsapp  = () =>{
    return (
        <a id="contact" href={`https://wa.me/${phone}`}>
            <figure id="whatsapp" />
        </a>
    )
};
export default Whatsapp