import { getAuth } from "firebase/auth";
import { getFirestore,doc,setDoc,getDoc,addDoc,collection} from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyDTg-XSHIjIJqVWjbzgYqWjmL6c5zu10J8",
  authDomain: "maraberto-36ed0.firebaseapp.com",
  projectId: "maraberto-36ed0",
  storageBucket: "maraberto-36ed0.firebasestorage.app",
  messagingSenderId: "379465625561",
  appId: "1:379465625561:web:ab6dcc0b633f4b184233c8"
};

// Inicialização de API
const app = initializeApp(firebaseConfig);

// Inicializações de constantes
const auth = getAuth();
const firestore = getFirestore();
const db = getFirestore(app);
const storage = getStorage(app);


//Método para gravação e atualização de informações em banco de dados
export const writeUserData = async (
  userEmail: string,
  data: {
    description: string;
    name: string;
    sureName: string;
    numCode: string;
    num: string;
    avatar: string;
    selectedCity: string;
    selectedLanguages: string[];
    type: string;
  }

) => {
  try {
    const userDoc = doc(firestore, `users/${userEmail}`); // Use userEmail as document ID
    await setDoc(userDoc, data, { merge: true }); // Merge to avoid overwriting existing fields
    console.log("User data successfully saved for:", userEmail);
  } catch (error) {
    console.error("Error saving user data:", error);
  }
};
//Método de carregamento de informações de conta em banco de dados
export const readUserData = async (userEmail: string) => {
  try {
    const userDocRef = doc(firestore, 'users', userEmail); 
    const docSnapshot = await getDoc(userDocRef);

    if (docSnapshot.exists()) {
      return docSnapshot.data(); 
    } else {
      console.error('No such document!');
      return null;
    }
  } catch (error) {
    console.error('Error getting document:', error);
    return null;
  }
};

//Método de adição de anuncios
export const addAnnouncementToFirestore = async (announcement: any) => {
  try {
    const docRef = await addDoc(collection(firestore, 'announcements'), announcement);
    return docRef.id; // Optionally return the document ID
  } catch (error) {
    console.error("Error adding announcement to Firestore: ", error);
    throw error;
  }
};
//Carregar um arquivo para o Firestore
export const uploadUserAvatar = async (userEmail: string, file: File): Promise<string> => {
  try {
    const storageRef = ref(storage, `avatars/${userEmail}`);
    await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(storageRef);
    return downloadURL; // Return the file's URL
  } catch (error) {
    console.error("Error uploading avatar:", error);
    throw error;
  }
};

// Receber URL do usuário
export const getUserAvatarURL = async (userEmail: string): Promise<string | null> => {
  try {
    const storageRef = ref(storage, `avatars/${userEmail}`);
    const downloadURL = await getDownloadURL(storageRef);
    return downloadURL;
  } catch (error) {
    console.error("Error fetching avatar URL:", error);
    return null;
  }
};

export { auth, firestore ,db,storage};
