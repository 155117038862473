import './index.scss'
const Dev = () => {
    return (
        <div className="dev-folder">
            <figure id="dev"></figure>
            <h3>DEV</h3>
            <h5>Fase-1</h5>
        </div>
    )
};
export default Dev