import "./style.scss";
import "swiper/css"; // Importação do CSS básico do Swiper
import "swiper/css/scrollbar"; // Importação do CSS do scrollbar
import { Swiper, SwiperSlide } from "swiper/react"; // Componentes React do Swiper
import { Autoplay, Scrollbar } from "swiper/modules"; // Módulos necessários
import { results } from "../content";
import { ResultCard } from "../../../components/result-card/result-card";
import { IExperiencia } from "../../../models/experiencia";

export function MaisProcurados() {
  const content: IExperiencia[] = results;

  return (
    <section className="mais-procurados-section">
      <h1 className="ocean-blue">Experiências mais procuradas</h1>
      <Swiper
        modules={[Autoplay, Scrollbar]}
        // autoplay={{
        //   delay: 3000,
        //   disableOnInteraction: false,
        // }}
        slidesPerView="auto"
        loop={true}
        breakpoints={{
          600: {
            spaceBetween: 60,
          },
        }}
      >
        {content.map((item, index) => (
          <SwiperSlide key={index}>
            <ResultCard content={item} />
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
}
