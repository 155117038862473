import "./style.scss";
import LogoBranca from "../../misc/logo-branca.svg";

export function Footer() {
  return (
    <footer>
      <img
        draggable="false"
        className="logo"
        src={LogoBranca}
        alt="logo mar aberto"
      />
      <div className="contact-info">
        <div><p>© 2023 MarAberto. Todos os direitos reservados</p></div>
        <div><p>marabertosocial@gmail.com</p></div>
        <div><p>(47) 98413-2542</p></div>
      </div>
    </footer>
  );
}
