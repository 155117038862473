import { Link } from "react-router-dom";
import "./style.scss";
import react, { useState } from "react";
import ImageInputList from "../../../components/lista-imagens/imageList";
import modalities from "../../../services/data/modalities.json";
import AvatarContainer from "../../../components/lista-imagens/avatarContainer";
import CustomSelect from "../../../components/selector/customSelect";
import default_avatar from "../../../misc/icons/default-user.png";
import DocumentInput from "../../../services/functions/documentValidator";
import { writeUserData } from "../../../services/firebase/firebaseConfig";
import { useAuth } from "../../../services/firebase/authContext";

const Sail = ({
  handleSubmit,
  formData,
  handleInputChange,
  modalityIndex,
}: any) => {
  const { userEmail, userImage, userName } = useAuth();
  const [selectedDays, setSelectedDays] = useState<string[]>([]);
  const [selectedType, setSelectedType] = useState(0);
  const [avatar, setAvatar] = useState<string | null>(null);
  const [instructorCheck, setInstructorCheck] = useState(false);
  const [overnight, setOvernight] = useState(false);
  const modality = modalities[modalityIndex];
  const [images, setImages] = useState<string[]>([]);

  const edit = true;

  const handleSave = async () => {
    try {
      // Check if userEmail is defined
      console.log("User Email:", userEmail);
      if (!userEmail) {
        console.error("Error: userEmail is undefined.");
        return;
      }
      try {
        const announcementData = {
          title: formData.title,
          description: formData.description,
          instructorName: formData.instructorName,
          instructorEmail: formData.instructorEmail,
          instructorNumcode: formData.instructorNumcode,
          instructorNumber: formData.instructorNumber,
          avatar: avatar || default_avatar,
          selectedDays: selectedDays,
          overnight: overnight,
          boatInfo: {
            name: formData.boatName,
            manufacturer: formData.manufacturer,
            year: formData.year,
            cabins: formData.cabins,
          },
          pricing: {
            individualPrice: formData.individualPrice,
            overnightPrice: formData.overnightPrice || null,
          },
          location: {
            address: formData.address,
            city: formData.city,
            state: formData.state,
          },
        };

        // Call Firebase function to write data
        // await writeUserData(userEmail, announcementData); // Replace "userEmailOrID" with appropriate user identifier
        console.log("Anúncio salvo com sucesso!");
      } catch (error) {
        console.error("Erro ao salvar o anúncio:", error);
      }
    } catch (error) {
      console.error("Erro em handle save", error);
    }
  };

  const daysOfWeek = [
    { name: "Domingo", value: "sunday" },
    { name: "Segunda-feira", value: "monday" },
    { name: "Terça-feira", value: "tuesday" },
    { name: "Quarta-feira", value: "wednesday" },
    { name: "Quinta-feira", value: "thursday" },
    { name: "Sexta-feira", value: "friday" },
    { name: "Sábado", value: "saturday" },
  ];

  // Função para mudanças em Checkbox
  const handleDayChange = (day: string) => {
    setSelectedDays((prevSelectedDays) =>
      prevSelectedDays.includes(day)
        ? prevSelectedDays.filter((d) => d !== day)
        : [...prevSelectedDays, day]
    );
  };

  const handleAvatarChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        setAvatar(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };
  //Função de tipo de embarcação a vela
  const handleTypeSelect = (index: number) => {
    setSelectedType(index);
  };
  const handleOvernight = () => {
    if (overnight !== true) {
      setOvernight(true);
    } else {
      setOvernight(false);
    }
  };

  return (
    <div className="placeholder" id="sail-place">
      {/**Seletor de tipo embarcação a vela*/}
      {/* <div className="type area" >
                <h2>Tipo de atividade</h2>
                <CustomSelect
                    items={modality.type.map((type) => ({ name: type }))}
                    onSelect={handleTypeSelect}
                    defaultText="Selecione um tipo"
                />
            </div> */}
      <div className="main-content">
        <div className="area">
          <span>Título</span>
          <textarea
            rows={1}
            cols={5}
            id="title"
            value={formData.title}
            onChange={(e) => handleInputChange("title", e.target.value)}
            placeholder="Insira o título do anúncio"
            required
          />
        </div>
        <div className="area">
          <span>Descreva o seu anúncio</span>
          <textarea
            id="description"
            value={formData.description}
            onChange={(e) => handleInputChange("description", e.target.value)}
            required
            rows={5}
            cols={50}
            placeholder="Insira o conteúdo do anúncio"
          />
        </div>
        <ImageInputList maxImages={8} onImagesChange={setImages} />
      </div>
      <div className="optional">
        <div className="instructor">
          <AvatarContainer
            avatar={avatar || default_avatar}
            edit={true}
            onAvatarChange={handleAvatarChange}
            id="instructor-avatar"
          />
          <div className="user-instructor">
            <input
              type="checkbox"
              id="instuctor-check"
              onChange={() =>
                instructorCheck === false
                  ? setInstructorCheck(true)
                  : setInstructorCheck(false)
              }
            />
            <span>Sou o instutor da atividade</span>
          </div>

          <div className="area area-2">
            <span>Nome do Instutor</span>
            <textarea
              rows={1}
              cols={4}
              value={formData.instructorName}
              onChange={(e) =>
                handleInputChange("instuctorName", e.target.value)
              }
              placeholder="Insira o nome do instutor"
            />
          </div>
          <div className="area area-2">
            <span>Email do Instutor</span>
            <textarea
              rows={1}
              cols={4}
              value={formData.instructorEmail}
              onChange={(e) =>
                handleInputChange("instuctorEmail", e.target.value)
              }
              placeholder="Insira o email do instutor"
            />
          </div>
          <div className="content-area">
            <div className="area">
              <span>Telefone</span>
              <div className="number">
                <textarea
                  className="text line"
                  id="numcode"
                  maxLength={2}
                  rows={1}
                  cols={1}
                  value={formData.instructorNumcode}
                  onChange={(e) =>
                    handleInputChange("instructorNumcode", e.target.value)
                  }
                  placeholder="XX"
                />
                <textarea
                  className="text line"
                  id="number"
                  rows={1}
                  cols={2}
                  value={formData.instructorNumber}
                  onChange={(e) =>
                    handleInputChange("instructorNumber", e.target.value)
                  }
                  placeholder="0000-0000"
                />
              </div>
            </div>
            <div className="area">
              <span>Documento</span>
              <DocumentInput
                disabled={!edit}
                onChange={(field, value) => handleInputChange(field, value)}
              />
            </div>
          </div>
        </div>
        <div className="area area-3">
          <span>Documento de habilitação (Arrais)</span>
          <textarea rows={1} cols={5} />
        </div>
        <h2 className="title-area">Informações do barco:</h2>
        <div className="content-area">
          <div className="area area-3">
            <span>Nome da embarcação</span>
            <textarea rows={1} cols={6} placeholder="Insira o nome da embarcação"/>
          </div>
          <div className="area area-3">
            <span>Fabricante</span>
            <textarea rows={1} cols={6} placeholder="Insira o fabricante"/>
          </div>
        </div>
        <div className="content-area">
          <div className="area area-3">
            <span>Ano de fabricação</span>
            <textarea typeof="number" rows={1} cols={6} placeholder="Insira o ano de fabricação"/>
          </div>
          <div className="area area-3">
            <span>Quantidade de cabines</span>
            <textarea typeof="number" rows={1} cols={6} placeholder="Insira a quantidade de cabines"/>
          </div>
        </div>
        <div className="checkbox-group">
          <span className="title">Nível do curso</span>
          <div className="input">
            <input type="checkbox" onChange={handleOvernight} />
            <label>Passeio com pernoite</label>
          </div>
        </div>
        
        <div className="content-area">
        <div className="area area-3">
          <span className="title">Quantidade de pessoas</span>
          <input type="number" placeholder="Insira a quantidade de pessoas"/>
        </div>
        <div className="area area-3">
          <span className="title">Tempo de passeio</span>
          <input type="number" placeholder="Insira o tempo de passeio"/>
        </div>
        </div>

        <div className="checkbox-group">
          <span className="title">Datas disponíveis</span>
          {daysOfWeek.map((day) => (
            <div key={day.value} className="input">
              <input
                type="checkbox"
                id={day.value}
                checked={selectedDays.includes(day.name)}
                onChange={() => handleDayChange(day.name)}
              />
              <label htmlFor={day.value}>{day.name}</label>
            </div>
          ))}
        </div>
        <span className="title">
          Dias selecionados: {selectedDays.join(", ")}
        </span>
        <h2 className="title-area">Valores:</h2>
        <div className="content-area">
        <div className="area area-3">
          <span>Valor do passeio individual</span>
          <textarea typeof="number" rows={1} cols={6} placeholder="Insira o valor do passeio"/>
        </div>
        {overnight ? (
          <div className="area area-3">
            <span>Valor Pernoite </span>
            <textarea typeof="number" rows={1} cols={6} placeholder="Insira o valor da pernoite"/>
          </div>
        ) : (
          <></>
        )}
        </div>

        <h2 className="title-area">Localização:</h2>
        <div className="area area-3">
          <span>Endereço do anúncio</span>
          <textarea id="adress" cols={6} rows={3} placeholder="Insira o endereço do anúncio"/>
        </div>
        <div className="content-area">
        <div className="area area-3">
          <span>Cidade</span>
          <textarea id="adress" cols={6} rows={1} placeholder="Insira a cidade"/>
        </div>
        <div className="area area-3">
          <span>Estado</span>
          <textarea id="adress" cols={6} rows={1} placeholder="Insira o estado"/>
        </div>
        </div>
      </div>
      <div className="terms">
        <input type="checkbox" />
        <label>
          Esse anúncio está de acordo com os termos de serviço para{" "}
          <Link to={"/seguro/anunciante"} className="link">
            Seguro Aventura
          </Link>
        </label>
      </div>

      <div className="bottom">
        <button id="cancel" onClick={() => window.location.reload()}>
          Cancelar
        </button>
        <button id="create" onClick={handleSave}>
          Salvar
        </button>
      </div>
    </div>
  );
};
export default Sail;
