import React, { useState } from 'react';
import './style.scss';
import por from '../../misc/flags/brasil.png';
import eng from '../../misc/flags/us.png';
import esp from '../../misc/flags/espanha.png';
import deu from '../../misc/flags/de.png'
import data from '../../services/data/language.json'

const LanguageSelect = () => {
  const [selectedLanguage, setSelectedLanguage] = useState('Português');
  const [flag, setFlag] = useState(por);
  const languages = [
    { code: '', name: 'Português', flag: por },
    { code: 'eng', name: 'English', flag: eng },
    { code: 'esp', name: 'Español', flag: esp },
    {code : 'ger',name:'Deutsch', flag : deu}
  ];

  const handleLanguageChange = (language: string, flag: string) => {
    setSelectedLanguage(language);
    setFlag(flag);
  };

  return (
    <div className="language-select">
      <div className="selected-option">
        <img src={flag} alt="Selected flag" className="flag-icon flag-selected" />
        <span className='span-select span-lang'>{selectedLanguage}</span>
      </div>
      <ul className="options-list">
        
        {languages
          .filter((lang) => lang.name !== selectedLanguage) // Filtrar o idioma selecionado
          .map((lang) => (
            <li
              key={lang.code}
              onClick={() => handleLanguageChange(lang.name, lang.flag)}
              >
              <img src={lang.flag} alt={`${lang.name} flag`} className="flag-icon" />
              <span className='span-lang'>{lang.name}</span>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default LanguageSelect;
