import "./new.scss";
import { useState } from "react";
import { addAnnouncementToFirestore } from "../../services/firebase/firebaseConfig";
import modalities from "../../services/data/modalities.json";
import CustomSelect from "../../components/selector/customSelect";
import defaultIcon from "../../misc/icons/arrow.png";

// Import page components
import Sail from "./forms/sail";
import Boat from "./forms/boat";
import Sports from "./forms/sports";

const NewAnnounce = ({ formData, handleInputChange }: any) => {
  const [selectedModality, setSelectedModality] = useState<number | null>(null);
  const [selectedIcon, setSelectedIcon] = useState(defaultIcon);

  // Handle modality change
  const handleModalityChange = (index: number, icon: string | undefined) => {
    setSelectedModality(index);
    setSelectedIcon(icon || defaultIcon);
  };

  // Render appropriate component
  const renderModalityComponent = () => {
    switch (selectedModality) {
      case 0:
        return (
          <Sail
            formData={formData}
            handleInputChange={handleInputChange}
            modalityIndex={selectedModality}
          />
        );
      case 1:
        return (
          <Boat
            formData={formData}
            handleInputChange={handleInputChange}
            modalityIndex={selectedModality}
          />
        );
      case 2:
        return (
          <Sports
            formData={formData}
            handleInputChange={handleInputChange}
            modalityIndex={selectedModality}
          />
        );
    }
  };

  const handleAnnouncementSubmit = async () => {
    try {
      window.location.reload();
      const announcement = {
        title: formData.title,
        description: formData.description,
        modality:
          selectedModality !== null
            ? modalities[selectedModality].name
            : "None",
      };

      await addAnnouncementToFirestore(announcement);
    } catch (error) {
      console.error("Erro ao criar o anúncio: ", error);
    }
  };

  const [selectedType, setSelectedType] = useState(0);

  const modality = modalities[selectedModality || 0];

  //Função de tipo de embarcação a vela
  const handleTypeSelect = (index: number) => {
    setSelectedType(index);
  };

  return (
    <div className="announcement-form">
      <div className="content-form">
        <div className="advice head-form">
          <button
            className="event-button element"
            id="previous"
            onClick={() => window.location.reload()}
          />
          <span className="element">Voltar sem salvar</span>
        </div>
        <div className="selector-form">
          <div className="selector">
            <h2>Selecione uma modalidade</h2>
            <CustomSelect
              items={modalities}
              onSelect={handleModalityChange}
              defaultText="Modalidade"
            />
          </div>
          {selectedModality !== null && (
            <div className={`type ${selectedType >= 0 ? "active" : ""}`}>
              <h2>Tipo de atividade</h2>
              <CustomSelect
                items={modality?.type.map((type) => ({ name: type }))}
                onSelect={handleTypeSelect}
                defaultText="Selecione um tipo"
              />
            </div>
          )}
        </div>
      </div>
      {renderModalityComponent()}
    </div>
  );
};

export default NewAnnounce;
