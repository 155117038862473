
import React, { createContext, useContext, useState, useEffect } from 'react';
import { onAuthStateChanged, User } from 'firebase/auth';
import { auth, firestore } from './firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';

// Definição do tipo para o contexto de autenticação
interface AuthContextType {
  userName: string | null;
  setUserName: (name: string | null) => void;
  userImage: string | null;
  setUserImage: (image: string | null) => void;
  userEmail: string | null;
  setUserEmail: (email: string | null) => void;
  userData: Record<string, any> | null;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [userName, setUserName] = useState<string | null>(null);
  const [userImage, setUserImage] = useState<string | null>(null);
  const [userEmail, setUserEmail] = useState<string | null>(null);
  const [userData, setUserData] = useState<Record<string, any> | null>(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user: User | null) => {
      if (user) {
        setUserName(user.displayName);
        setUserImage(user.photoURL);
        setUserEmail(user.email);

        if (user.email) {
          try {
            const userDocRef = doc(firestore, 'users', user.email);
            const docSnapshot = await getDoc(userDocRef);

            if (docSnapshot.exists()) {
              setUserData(docSnapshot.data());
            } else {
              console.log('No user data found in Firestore');
              setUserData(null);
            }
          } catch (error) {
            console.error('Error fetching user data from Firestore:', error);
            setUserData(null);
          }
        }
      } else {
        setUserName(null);
        setUserImage(null);
        setUserEmail(null);
        setUserData(null);
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider value={{ userName, setUserName, userImage, setUserImage, userEmail, setUserEmail, userData }}>
        {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
