import { DetalhesExperienciaMarSocial } from "./pages/marsocial/detalhes-experiencia/detalhes-experiencia";
import { ProfileReservasRealizadas } from "./pages/userprofilereservasrealizadas/profilereserva";
import { DetalhesExperiencia } from "./pages/detalhes-experiencia/detalhes-experiencia";
import { ProfileReservas } from "./pages/userprofilereservas/profilereserva";
import { ProfileSenha } from "./pages/userprofilesenha/profileSenha";
import CadastrarBarco from "./pages/cadastrar-barco/cadastrarBarco";
import { ProfileHist } from "./pages/userprofilehist/profilehist";
import { HomeMarSocial } from "./pages/marsocial/home/home";
import DetalhesPasseio from "./pages/passeio/passeio";
import { Profile } from "./pages/userprofile/profile";
import UserAdv from "./pages/announces/userAnnounce";
import UserPage from "./pages/advertiser/userPage";
import { Route, Routes } from "react-router-dom";
import Seguro from "./pages/politics/announcer";
import { Ajuda } from "./pages/ajuda/ajuda";
import NotFound from "./pages/404/notFound";
import { Busca } from "./pages/busca/busca";
import { Home } from "./pages/home/home";
import "./App.scss";

// Importações para firebase
import 'firebase/auth';

const App = () => {
  return (
      <Routes>
       1 <Route path="/" element={<Home />} />
       2 <Route path="/buscar-experiencias" element={<Busca />} />
       3 <Route path="/marsocial" element={<HomeMarSocial />} />
       4 <Route path="/marsocial/:id/detalhes" element={<DetalhesExperienciaMarSocial />} />
       5 <Route path="/experiencia/:id/detalhes" element={<DetalhesExperiencia />} />
       6 <Route path="/ajuda" element={<Ajuda />} />

       9 <Route path="/userprofile" element={<Profile />} />{" "}
        <Route path="/passeio/:id" element={<DetalhesPasseio />} />
        <Route path="/cadastrar-barco" element={<CadastrarBarco />} />
        <Route path="/userprofile/senha" element={<ProfileSenha />} />
        <Route path="/userprofile/reservas" element={<ProfileReservas />} />
        <Route path="/userprofile/reservas/realizadas" element={<ProfileReservasRealizadas />} />
        <Route path="/userprofile/reservas/hist" element={<ProfileHist />} />

       7 <Route path='/anunciante/conta' element={<UserPage />} />
       8 <Route path='/anunciante/anuncios' element={<UserAdv />} />

        <Route path='/seguro/anunciante' element={<Seguro />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
  );
}
export default App;