import './confirm.scss';
import React from 'react';

interface ConfirmPopupProps {
  onResendEmail: () => void;
  onClose: () => void;
}

const ConfirmPopup: React.FC<ConfirmPopupProps> = ({ onResendEmail, onClose }) => {
  return (
    <div className="confirm">
      <div className="confirm-background"></div>
      <p>Seu email ainda não foi verificado. Verifique seu email antes de continuar.</p>
      <button type="button" className="link" onClick={onResendEmail}>
        Reenviar email
      </button>
      <button type="button" className="close" onClick={onClose}>
        Fechar
      </button>
    </div>
  );
};

export default ConfirmPopup;
