import "./style.scss";
import { ResultCard } from "../../../components/result-card/result-card";
import { IExperiencia } from "../../../models/experiencia";
import Lottie from "lottie-react";
import errorSearch from "../../../misc/animations/errorSearch.json";

interface IResult {
  result: IExperiencia[];
}

export function Result(props: IResult) {
  let content = props.result;

  if (content.length === 0) {
    return (
      <div className="result-erro">
        <h1>Não foi possivel encontrar essa experiência</h1>
        <section className="result-container-erro">
          <Lottie animationData={errorSearch} height={100} width={100} />
        </section>
      </div>
    );
  }
  return (
    <section className="result-container">
      <h2>{`${content.length} Resultados encontrados`}</h2>
      <div className="results">
        {content.map((item) => {
          return <ResultCard content={item} />;
        })}
      </div>
    </section>
  );
}
