// announcementService.tsx
import { firestore } from './firebaseConfig';
import { doc, setDoc, deleteDoc, collection, getDocs } from 'firebase/firestore';

export const addAnnouncementToFirestore = async (formData: any, userEmail: string) => {
    try {
        // Custom ID for the announcement
        const announcementId = `${userEmail}-${Date.now()}`;
        const announcementRef = doc(firestore, 'announcements', announcementId);

        await setDoc(announcementRef, { ...formData, userEmail, id: announcementId });
        console.log('Anúncio criado com ID customizado:', announcementId);
    } catch (error) {
        console.error('Erro ao criar anúncio:', error);
        throw error;
    }
};

export const getAnnouncementsFromFirestore = async () => {
    try {
        const querySnapshot = await getDocs(collection(firestore, 'announcements'));
        return querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
    } catch (error) {
        console.error('Erro ao buscar anúncios:', error);
        throw error;
    }
};

export const deleteAnnouncementFromFirestore = async (announcementId: string) => {
    try {
        const announcementRef = doc(firestore, 'announcements', announcementId);
        await deleteDoc(announcementRef);
        console.log('Anúncio removido com sucesso:', announcementId);
    } catch (error) {
        console.error('Erro ao remover anúncio:', error);
        throw error;
    }
};
