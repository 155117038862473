import React, { useState } from 'react';
import "./style.scss"

type ImageInputListProps = {
    maxImages: number;
    onImagesChange: (images: string[]) => void;
};

const ImageInputList: React.FC<ImageInputListProps> = ({ maxImages,onImagesChange }) => {
    const [images, setImages] = useState<string[]>([]); // Store the base64 image URLs

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files) {
            const fileArray = Array.from(files).slice(0, maxImages - images.length); // Limit to max images
            fileArray.forEach((file) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    setImages((prevImages) => {
                        const newImages = [...prevImages, reader.result as string]
                        onImagesChange(newImages);
                        return newImages
                    })
                };
                reader.readAsDataURL(file);
            });
        }
    };

    const handleDelete = (index: number) => {
        setImages((prevImages)=>{
            const newImages = prevImages.filter((_,i)=> i !==index);
            onImagesChange(newImages);
            return newImages
        })
        setImages((prevImages) => prevImages.filter((_, i) => i !== index));
    };

    return (
        <div className="image-input-list">
            <label htmlFor="image-upload" className="image-upload-label">
                <input
                    type="file"
                    id="image-upload"
                    accept="image/*"
                    multiple
                    onChange={handleImageChange}
                    disabled={images.length >= maxImages}
                />
                <span>{images.length < maxImages ? "Inserir imagens" : `Limite máximo de imagens: ${maxImages}`}</span>
            </label>

            <div className="image-preview-list">
                {images.map((image, index) => (
                    <div key={index} className="image-preview-item">
                        <img src={image} alt={`Preview ${index}`} className="image-preview" />
                        <button onClick={() => handleDelete(index)} className="delete-button">
                            ✕
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ImageInputList;
