/* eslint-disable jsx-a11y/anchor-is-valid */
import "./profilestyle.scss";
import { useState } from "react";
import { useAuth } from "../../services/firebase/authContext";
import ConfirmPopup from "../advertiser/popup/confirmPopup";
import AvatarContainer from "../../components/lista-imagens/avatarContainer";
import default_avatar from "../../misc/icons/default-user.png";
import IconEditar from "../../misc/icons/user_edit.png";
import IconSalvar from "../../misc/icons/user-check.png";
import IconReset from "../../misc/icons/user_reset.png";

export function Profile() {
  const { userEmail, userImage, userName } = useAuth();
  
  const [avatar, setAvatar] = useState(userImage || default_avatar);
  const [user, setUser] = useState({
    name: userName ? userName : "João da Silva",
    numCode: "11",
    num: "9 9999-9999",
    cep: "99999-999",
    address: {
      rua: "Rua Sustentabilidade Verde",
      complemento: "Casa",
      numero: "123",
      cidade: "São Paulo",
      estado: "SP",
    },
  });

  const [edit, setEdit] = useState(false);
  const [type, setType] = useState("Cliente");
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  // Função para alterar o avatar
  const handleAvatarChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setAvatar(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  // Alternar entre editar e salvar
  const handleEdit = () => {
    setEdit(!edit);
  };

  // Função para salvar os dados (simula a persistência)
  const handleSave = () => {
    console.log("Dados salvos:", user);
  };

  // Salvar e alternar o modo de edição
  const saveAndChange = () => {
    handleSave();
    setEdit(false);
  };

  // Função para resetar os dados
  const handleSetDefault = () => {
    setUser({
      name: userName ? userName : "João da Silva",
      numCode: "11",
      num: "9 9999-9999",
      cep: "99999-999",
      address: {
        rua: "Rua Sustentabilidade Verde",
        complemento: "Casa",
        numero: "123",
        cidade: "São Paulo",
        estado: "SP",
      },
    });
  };

  // Mostrar popup de confirmação de reset
  const handleResetClick = () => {
    setIsPopupVisible(true);
  };

  const handleConfirmReset = () => {
    handleSetDefault();
    setIsPopupVisible(false);
  };

  const handleCancelReset = () => {
    setIsPopupVisible(false);
  };

  // Atualizar os valores dos campos dinamicamente
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, field: string, nestedField?: string) => {
    if (nestedField) {
      setUser((prev) => ({
        ...prev,
        address: {
          ...prev.address,
          [nestedField]: e.target.value,
        },
      }));
    } else {
      setUser((prev) => ({
        ...prev,
        [field]: e.target.value,
      }));
    }
  };

  return (
    <>
      <div className="menus">
        <div className="profile-wrapper">
          <div className="buttons-wrapper">
            <div className="buttons-container">
              <a href="/userprofile" className="perfil">
                Perfil
              </a>
              <a href="/userprofile/senha" className="senha">
                Senha
              </a>
            </div>
            <div className="buttons">
              <button
                className="edit-button"
                onClick={edit ? saveAndChange : handleEdit}
                id={`${edit ? "save" : "edit"}`}
              >
                <img src={edit ? IconSalvar : IconEditar} alt="Editar ou Salvar" />
                {edit ? "Salvar" : "Editar Perfil"}
              </button>
              {edit && (
                <button className="edit-button" onClick={handleResetClick} id="reset">
                  <img src={IconReset} alt="Resetar" />
                  Resetar
                </button>
              )}
            </div>
          </div>

          <div className="account-info">
            <div className="profile-section">
              <AvatarContainer
                avatar={avatar}
                edit={edit}
                onAvatarChange={handleAvatarChange}
                id="user-avatar"
              />
              <div className="userbox">
                <span id="type">Conta {type}</span>
                <h2>{user.name}</h2>
                <p>
                  <strong>Email:</strong> {userEmail}
                </p>
              </div>
            </div>
          </div>

          <div className="profile-info-wrapper">
            <form action="">
              <div className="field">
                <p>Nome</p>
                <input
                  type="text"
                  value={user.name}
                  placeholder={userName}
                  disabled={!edit}
                  onChange={(e) => handleInputChange(e, "name")}
                />
              </div>
              <div className="field">
                <p>Telefone</p>
                <div className="number">
                  <input
                    id="numcode"
                    maxLength={2}
                    type="text"
                    value={user.numCode}
                    disabled={!edit}
                    onChange={(e) => handleInputChange(e, "numCode")}
                  />
                  <input
                    id="number"
                    type="text"
                    value={user.num}
                    disabled={!edit}
                    onChange={(e) => handleInputChange(e, "num")}
                  />
                </div>
              </div>
              <div className="field">
                <p>Email</p>
                <input type="email" value={userEmail || ""} placeholder="example@example.com" disabled />
              </div>
              <div className="adress-fields">
                <div className="field">
                  <p>CEP</p>
                  <input
                    type="text"
                    value={user.cep}
                    placeholder="Digite seu CEP"
                    disabled={!edit}
                    onChange={(e) => handleInputChange(e, "cep")}
                  />
                </div>
                <div className="field">
                  <p>Nome da Rua</p>
                  <input
                    type="text"
                    value={user.address.rua}
                    placeholder="Digite sua rua"
                    disabled={!edit}
                    onChange={(e) => handleInputChange(e, "address", "rua")}
                  />
                </div>
              </div>
              <div className="adress-fields">
                <div className="field">
                  <p>Complemento</p>
                  <input
                    type="text"
                    value={user.address.complemento}
                    placeholder="Digite o complemento"
                    disabled={!edit}
                    onChange={(e) => handleInputChange(e, "address", "complemento")}
                  />
                </div>
                <div className="field">
                  <p>Número</p>
                  <input
                    type="text"
                    value={user.address.numero}
                    placeholder="Digite o número"
                    disabled={!edit}
                    onChange={(e) => handleInputChange(e, "address", "numero")}
                  />
                </div>
              </div>
              <div className="adress-fields">
                <div className="field">
                  <p>Cidade</p>
                  <input
                    type="text"
                    value={user.address.cidade}
                    placeholder="Digite sua cidade"
                    disabled={!edit}
                    onChange={(e) => handleInputChange(e, "address", "cidade")}
                  />
                </div>
                <div className="field">
                  <p>Estado</p>
                  <input
                    type="text"
                    value={user.address.estado}
                    placeholder="Digite seu estado"
                    disabled={!edit}
                    onChange={(e) => handleInputChange(e, "address", "estado")}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
        <ConfirmPopup
          isVisible={isPopupVisible}
          onConfirm={handleConfirmReset}
          onCancel={handleCancelReset}
        />
      </div>
    </>
  );
}
