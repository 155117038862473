import "./style.scss";
import { useState, useEffect } from "react";
import { useAuth } from "../../services/firebase/authContext";
import {
  writeUserData,
  readUserData,
  uploadUserAvatar,
  getUserAvatarURL,
} from "../../services/firebase/firebaseConfig";
import default_avatar from "../../misc/icons/default-user.png";
import ConfirmPopup from "./popup/confirmPopup";
import AvatarContainer from "../../components/lista-imagens/avatarContainer";
import IconEditar from "../../misc/icons/user_edit.png";
import IconSalvar from "../../misc/icons/user-check.png";
import IconReset from "../../misc/icons/user_reset.png";
import Form from "../../components/form/form";

const AdvertiserAccount: React.FC = () => {
  //dados inseridos pelo usuário
  const { userEmail, userImage, userName } = useAuth();
  console.log(userName)
  const [edit, setEdit] = useState(false);
  const [description, setDescription] = useState("");
  const [name, setName] = useState("");
  const [sureName, setSureName] = useState("");
  const [numCode, setNumCode] = useState("55");
  const [num, setNum] = useState("");
  const [avatar, setAvatar] = useState(userImage!);
  const [selectedCity, setSelectedCity] = useState("Selecione uma cidade");
  const [selectedLanguages, setSelectedLanguages] = useState<string[]>([
    "Português",
  ]);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [type, setType] = useState("Anunciante");
  const handleEdit = () => {
    setEdit(!edit);
  };

  //Função de carregamento de dados, se existentes
  const loadUserData = async () => {
    if (!userEmail) return;
    const userData = await readUserData(userEmail);
    if (userData) {
      setDescription(userData.description || "Description");
      setName(userData.name || "João");
      setSureName(userData.sureName || "da Silva");
      setNumCode(userData.numCode || "55");
      setNum(userData.num || "48 9 9999-9999");
      const avatarURL =
        userData.avatar ||
        (await getUserAvatarURL(userEmail)) ||
        default_avatar;
      setAvatar(avatarURL);
      setSelectedCity(userData.selectedCity || "Selecione uma cidade");
      setSelectedLanguages(userData.selectedLanguages || ["Português"]);
      setType(userData.type || "Anunciante");
    }
  };

  // Load user data on component mount
  useEffect(() => {
    loadUserData();
  }, [userEmail]);

  //Função dupla => Salvar objeto no banco e alterar status da página
  const saveAndChange = () => {
    handleEdit();
    handleSave();
  };
  const handleSave = async () => {
    if (!userEmail) {
      console.error("User email is missing.");
      return;
    }

    // Criação de objeto para usuário
    const userData = {
      description,
      name,
      sureName,
      numCode,
      num,
      avatar,
      selectedCity,
      selectedLanguages,
      type,
    };

    // chamada de função para banco de dados
    await writeUserData(userEmail, userData);
    console.log("Dados salvos com sucesso");
  };

  //método para mudança de avatar
  const handleAvatarChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        setAvatar(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  //Método para fila de idiomas
  const handleLanguageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const language = e.target.value;
    setSelectedLanguages(
      (prev) =>
        e.target.checked
          ? [...prev, language] // Adicionar idiomas selecionados
          : prev.filter((l) => l !== language) // Remover idiomas caso não selecionados
    );
  };

  //método para retornar a padrão
  const handleSetDefault = () => {
    setDescription("");
    setName("");
    setSureName("");
    setNumCode("55");
    setNum("");
    setAvatar(userImage!);
    setSelectedCity("");
    setSelectedLanguages(["Português"]);
  };

  //método para popup de confirmação
  const handleResetClick = () => {
    setIsPopupVisible(true); // Mostrar janela de confirmação de reset
  };

  const handleConfirmReset = () => {
    handleSetDefault(); // Resetar dados
    setIsPopupVisible(false); // Esconder janela
  };
  // Fechar a janela sem resetar dados
  const handleCancelReset = () => {
    setIsPopupVisible(false);
  };

  return (
    <div className="account-container">
      <div className="account-menu">
        <div className="header">
          <h1>Minha conta</h1>
          <div className="buttons">
            <button
              className="edit-button"
              onClick={edit ? saveAndChange : handleEdit}
              id={`${edit ? `save` : `edit`}`}
            >
              {edit ? (
                <img src={IconSalvar} alt="Salvar"></img>
              ) : (
                <img src={IconEditar} alt="Editar"></img>
              )}
              {edit ? "Salvar" : "Editar Perfil"}
            </button>
            <>
              {edit ? (
                <button
                  className="edit-button"
                  onClick={handleResetClick}
                  id="reset"
                >
                  <img src={IconReset} alt="Resetar"></img>Resetar
                </button>
              ) : (
                <></>
              )}{" "}
            </>
          </div>
        </div>

        <div className="account-info">
          <div className="profile-section">
            <AvatarContainer
              avatar={avatar}
              edit={edit}
              onAvatarChange={handleAvatarChange}
              id="user-avatar"
            />
            <div className="userbox">
              <span id="type">Conta {type}</span>
              <h2>{name} {sureName}</h2>
              <p>
                <strong>Email:</strong> {userEmail}
              </p>
            </div>
          </div>
        </div>

        <Form
          filds={[
            {
              title: "Sobre o anunciante",
              textarea: true,
              id: "about",
              placeholder: description,
              value: description,
              className: "title",
              classNameDiv: "divField",
              disabled: !edit,
              onChange: (e) => setDescription(e.target.value),
            },
            {title: "Informações de contato"},
            {
              label: "Nome",
              type: "text",
              placeholder: name,
              value: name,
              className: "field-group",
              classNameDiv: "divField",
              disabled: !edit,
              onChange: (e) => setName(e.target.value),
            },
            {
              label: "Sobrenome",
              type: "text",
              placeholder: sureName,
              value: sureName,
              disabled: !edit,
              onChange: (e) => setSureName(e.target.value),
            },
            {
              label: "E-mail",
              type: "email",
              placeholder: userEmail,
              className: "field-groups",
              classNameDiv: "divField",
              value: userEmail,
              disabled: true,
            },
            {
              label: "Telefone",
              type: "string",
              id: "numcode",
              placeholder: numCode,
              value: numCode,
              style: { width: "25%" },
              disabled: !edit,
              onChange: (e) => setNumCode(e.target.value),
            },
            {
              type: "string",
              id: "num",
              placeholder: num,
              value: num,
              style: { justifyContent: "end" },
              disabled: !edit,
              onChange: (e) => setNum(e.target.value),
            },
            {
              selector: true,
              placeholder: selectedCity,
              options: ["Bombinhas", "Florianópolis", "Navegantes"],
              onChange: (e) => setSelectedCity(e.target.value),
              disabled: !edit,
            },
            {
              title: "Idiomas"
            },
            { checkbox: true, 
              disabled: !edit,
              className: "languages-edit",
              selectedLanguages: selectedLanguages,
              languages: [{
                id: "pt",
                label: "Português",
              },
              {
                id: "en",
                label: "Inglês",
              },
              {
                id: "es",
                label: "Espanhol",
              },
              {
                id: "de",
                label: "Alemão",
              }],
              onChange: (e) => handleLanguageChange(e),
              },
          ]}
        />

        {/* <div className="account-settings">
          <h3>Sobre o anunciante</h3>
          <textarea
            contentEditable={`${edit ? true : false}`}
            className={`text ${edit ? "editText" : false}`}
            id="about"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            disabled={!edit}
            rows={5}
            cols={50}
          />
          <h3>Informações de contato</h3>
          <div className="contact">
            <div className="info">
              <h4>Nome</h4>
              <textarea
                className={`text line ${edit ? "editText" : false}`}
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                disabled={!edit}
                rows={1}
                cols={2}
              />
            </div>
            <div className="info">
              <h4>Sobrenome</h4>
              <textarea
                className={`text line ${edit ? "editText" : false}`}
                id="surename"
                value={sureName}
                onChange={(e) => setSureName(e.target.value)}
                disabled={!edit}
                rows={1}
                cols={2}
              />
            </div>
            <div className="info">
              <h4>Email</h4>
              <textarea
                className={`text line ${edit ? "editText" : false}`}
                id="email"
                value={userEmail!}
                disabled={true}
                rows={1}
                cols={2}
              />
            </div>
            <div className="info">
              <h4>Telefone</h4>
              <div className="number">
                <textarea
                  className={`text line ${edit ? "editText" : false}`}
                  id="numcode"
                  maxLength={2}
                  value={numCode}
                  onChange={(e) => setNumCode(e.target.value)}
                  disabled={!edit}
                  rows={1}
                  cols={1}
                />
                <textarea
                  className={`text line ${edit ? "editText" : false}`}
                  id="number"
                  value={num}
                  onChange={(e) =>
                    setNum(e.target.value.replace(/^(\d{2})/, "($1)"))
                  }
                  disabled={!edit}
                  rows={1}
                  cols={2}
                />
              </div>
            </div>
            <div className="city-selector">
              <select
                value={selectedCity}
                onChange={(e) => setSelectedCity(e.target.value)}
                disabled={!edit}
                className={edit ? "editSelect" : false}
              >
                <option value="">Selecione uma cidade</option>
                <option value="Bombinhas">Bombinhas</option>
                <option value="Florianópolis">Florianópolis</option>
                <option value="Navegantes">Navegantes</option>
              </select>
            </div>
          </div>
          <h3>Idiomas</h3>
          {edit ? (
            <div className="languages-edit">
              <input
                type="checkbox"
                id="pt"
                value="Português"
                checked={selectedLanguages.includes("Português")}
                onChange={handleLanguageChange}
                disabled
              />
              <label htmlFor="pt">Português</label>

              <input
                type="checkbox"
                id="in"
                value="Inglês"
                checked={selectedLanguages.includes("Inglês")}
                onChange={handleLanguageChange}
              />
              <label htmlFor="in">Inglês</label>

              <input
                type="checkbox"
                id="es"
                value="Espanhol"
                checked={selectedLanguages.includes("Espanhol")}
                onChange={handleLanguageChange}
              />
              <label htmlFor="es">Espanhol</label>

              <input
                type="checkbox"
                id="de"
                value="Alemão"
                checked={selectedLanguages.includes("Alemão")}
                onChange={handleLanguageChange}
              />
              <label htmlFor="de">Alemão</label>

              <input
                type="checkbox"
                id="other"
                value="Outro"
                onChange={handleLanguageChange}
              />
              <label htmlFor="other">
                <input type="text" placeholder="Outro" />
              </label>
            </div>
          ) : (
            <div className="languages-display">
              <div className="languages-grid">
                {selectedLanguages.map((language) => (
                  <div key={language} className="language-box">
                    {language}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div> */}

        <div className="logout"></div>

        <ConfirmPopup
          isVisible={isPopupVisible}
          onConfirm={handleConfirmReset}
          onCancel={handleCancelReset}
        />
      </div>
    </div>
  );
};
export default AdvertiserAccount;
