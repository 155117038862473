import "./reset.css";
import "./index.scss";
import Dev from './dev';
import App from "./App";
import React from "react";
import ReactDOM from "react-dom/client";
import NavBar from "./components/navbar/navbar";
import Whatsapp from './components/especiais/whatsapp';
import { BrowserRouter } from "react-router-dom";
import { Footer } from "./components/footer/footer";
import { AuthProvider } from "./services/firebase/authContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <AuthProvider>
    <React.StrictMode>
      <BrowserRouter>
        <Dev /> 
        <NavBar />
        <App />
        <Footer />
        <Whatsapp />
      </BrowserRouter>
    </React.StrictMode>
  </AuthProvider>
);
