import { useState, useEffect } from 'react';
import "./style.scss";
function TextSlider() {
    const titulos = ["Explore Aventuras Marítimas", "Conecte-se na natureza", "Possui um serviço turístico?", "Experiência com Segurança"]
    const textos = ["Navegue pelo nosso catálogo e reserve barcos, mergulhos, e muito mais. Transforme seu passeio marítimo em uma experiência única", "Explore o mar e sua beleza natural com a Mar Aberto. Uma experiência eco-friendly inesquecível.", "Anuncie seu serviço turístico na Mar Aberto. Confira nosso regulamento", "Navegue com tranquilidade! Nossa plataforma oferece equipamentos e serviços de aluguel marítimo com total segurança para sua aventura."];
    const delay = 7000;
    const [currentIndex, setCurrentIndex] = useState(0)
    const [fade,setFade] = useState(true);
    useEffect(() => {
        const intervalId = setInterval(() => {
            setFade(false);
            setTimeout(() => {
                setCurrentIndex((prevIndex) =>
                    prevIndex === textos.length - 1 ? 0 : prevIndex + 1
                );
                setFade(true);
            }, 500);
        }, delay);

        return () => clearInterval(intervalId);
    }, [textos.length, delay]);
    return (
        <>
            <div className="sliderContainer">
                <div className="elementos">

                    <h2 className={`text ${fade?'enter':'exit'}`}>
                        {titulos[currentIndex]}
                    </h2>
                    <div className={`text ${fade?'enter':'exit'}`}>
                        {textos[currentIndex]}
                    </div>
                </div>
            </div>
        </>
    )
}
export default TextSlider;
