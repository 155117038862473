import './style.scss';
import { useAuth } from '../../services/firebase/authContext';
import { Link } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import GoogleSignOut from '../login-pop-up/signOut';
import default_avatar from '../../misc/icons/default-user.png'
import { doc, getDoc } from "firebase/firestore"
import { db } from '../../services/firebase/firebaseConfig';


const MenuPopup: React.FC = () => {
    // Importações baseadas em variáveis universais de usuário
    const { userEmail, userImage, userName } = useAuth();

    // Mudança de classe para usermenu
    const [visible, setVisible] = useState(false);
    const popupRef = useRef<HTMLDivElement>(null);
    const [userType, setUserType] = useState<string | null>(null);
    const handleAvatarClicked = () => {
        setVisible(!visible);
    };
    const teste = userName

    useEffect (() =>{
        const handleClickOutside = (event: MouseEvent) => {
            if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
                setVisible(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
    };
}, [popupRef]);


return (
    <div className="mainPopup" ref={popupRef}>
        <img
            id="avatar"
            src={userImage || default_avatar}
            onClick={handleAvatarClicked}
            alt="User Avatar"
        />
        <div className={`pre-content ${visible ? '' : 'pre-visible'}`} style={visible ? {display: "block"} : {display: "none"}}>
            <span className='item'>Conta {userType}</span>
            <span className='item'>{userName}</span>
        </div>
        <div className={`content ${visible ? 'content-visible' : ''}`} style={visible ? {display: "flex"} : {display: "none"}}>
            <span>{teste}</span>
            <span className='item name'>{userName}</span>
            <Link className='item link' to='/anunciante/conta'>Minha conta</Link>
            {userType === "Cliente"?
            <Link className='item link' to='/buscar-experiencias'>Procurar experiências</Link>
            :
            <Link className='item link' to='/anunciante/anuncios'>Meus anúncios</Link>
            }
            <span className='item'> <GoogleSignOut /> </span>
        </div>
    </div>
);
};

export default MenuPopup;
