import './style.scss'
import React, { useState } from "react";

interface ForgotPasswordProps {
  onClose: () => void;
  onPasswordReset: (email: string) => void; 
}

const ForgotPassword: React.FC<ForgotPasswordProps> = ({ onClose, onPasswordReset }) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleSubmit = () => {
    if (!email) {
      setError("Por favor, insira seu email.");
      return;
    }
    setError("");
    onPasswordReset(email);
    setSuccess("Você receberá instruções para redefinir sua senha.");
  };

  return (
    <div className="square">
      
    </div>
    // <div className="forgot-password-overlay">
    //   <div className="forgot-password-container">
    //     <button className="close-button" onClick={onClose}>
    //       ×
    //     </button>
    //     <h2>Redefinir Senha</h2>
    //     <p>Insira o email associado à sua conta.</p>
    //     <input
    //       type="email"
    //       placeholder="Seu email"
    //       value={email}
    //       onChange={handleEmailChange}
    //     />
    //     {error && <span className="error">{error}</span>}
    //     {success && <span className="success">{success}</span>}
    //     <button className="submit-button" onClick={handleSubmit}>
    //       Redefinir senha
    //     </button>
    //   </div>
    // </div>
  );
};

export default ForgotPassword;
