import "./style.scss";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAuth } from "../../../services/firebase/authContext";
import ImageInputList from "../../../components/lista-imagens/imageList";
import modalities from "../../../services/data/modalities.json";
import CustomSelect from "../../../components/selector/customSelect";
import DocumentInput from "../../../services/functions/documentValidator";
import default_avatar from "../../../misc/icons/default-user.png";
import AvatarContainer from "../../../components/lista-imagens/avatarContainer";
import { getUserAvatarURL } from "../../../services/firebase/firebaseConfig";
import { doc, setDoc, collection } from "firebase/firestore";
import { db } from "../../../services/firebase/firebaseConfig";

const Sports = ({
  handleSubmit,
  formData,
  handleInputChange,
  modalityIndex,
}: any) => {
  const [selectedType, setSelectedType] = useState<number | null>(0);
  const [selectedDays, setSelectedDays] = useState<string[]>([]);
  const [instructorCheck, setInstructorCheck] = useState(false);
  const { userEmail, userName, userImage } = useAuth();
  const [avatar, setAvatar] = useState<string | null>(userImage || null);
  const modality = modalities[modalityIndex];
  const edit = true;
  const [images, setImages] = useState<string[]>([]);
  const [allow, setAllow] = useState(false);

  //Variáveis para agendemento de datas
  const daysOfWeek = [
    { name: "Domingo", value: "sunday" },
    { name: "Segunda-feira", value: "monday" },
    { name: "Terça-feira", value: "tuesday" },
    { name: "Quarta-feira", value: "wednesday" },
    { name: "Quinta-feira", value: "thursday" },
    { name: "Sexta-feira", value: "friday" },
    { name: "Sábado", value: "saturday" },
  ];

  //Save de dados para anuncio
  const saveAnnouncement = async () => {
    //Checagem para userEmail
    if (!userEmail) return;

    //Dados do anuncio
    const announcementData = {
      type: selectedType,
      title: formData.title,
      description: formData.description,
      days: selectedDays,
      userAvatar: avatar || default_avatar,
      instructor: instructorCheck
        ? {
            name: formData.instructorName,
            email: formData.instructorEmail,
            numcode: formData.instructorNumcode,
            number: formData.instructorNumber,
            doc: formData.instructorDoc,
            avatar: avatar || default_avatar,
          }
        : null,
    };

    try {
      const userDocRef = doc(db, "users", userEmail);
      const announceID = `announce-${Date.now()}`;
      const announcementRef = doc(
        collection(userDocRef, "announcements"),
        announceID
      );
      //Salvar o documento
      await setDoc(announcementRef, announcementData);
    } catch (error) {
      console.log("Erro ao salvar anúncio: ", error);
    }

    window.location.reload();
  };

  // Mudança de checkbox
  const handleDayChange = (day: string) => {
    setSelectedDays((prevSelectedDays) =>
      prevSelectedDays.includes(day)
        ? prevSelectedDays.filter((d) => d !== day)
        : [...prevSelectedDays, day]
    );
  };

  // CustomSelect para tipo de evento
  const handleTypeSelect = (index: number, icon: string | undefined) => {
    setSelectedType(index);
  };

  //Mudança de avatar
  const handleAvatarChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        setAvatar(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  //Função de auto preenchimento de instrutor
  useEffect(() => {
    const fetchUserAvatar = async () => {
      if (userEmail && instructorCheck) {
        const avatarURL = await getUserAvatarURL(userEmail);
        setAvatar(avatarURL || default_avatar);
        console.log("URL do avatar", avatar?.toString);
      } else {
        setAvatar(default_avatar);
      }
    };
    fetchUserAvatar();

    if (instructorCheck) {
      handleInputChange("instructorName", userName || "");
      handleInputChange("instructorNumcode", "55");
      handleInputChange("instructorNumber", "48988337373");
      handleInputChange("instructorEmail", userEmail || "");
      handleInputChange("instructorDoc", "0000222");
      handleInputChange("instructorAvatar", avatar || "");
    } else {
      handleInputChange("instructorName", "");
      handleInputChange("instructorNumcode", "");
      handleInputChange("instructorNumber", "");
      handleInputChange("instructorEmail", "");
      handleInputChange("instructorDoc", "");
      handleInputChange("instructorAvatar", "");
    }
  }, [instructorCheck, userName, userEmail, avatar]);

  return (
    <div className="placeholder" id="swim-place">
      {/* <div className="type area" >
                <h2>Tipo de atividade</h2>
                <CustomSelect
                    items={modality.type.map((type) => ({ name: type }))}
                    onSelect={handleTypeSelect}
                    defaultText="Selecione um tipo"
                />
            </div> */}
      <div className="main-content">
        <div className="area">
          <span>Título</span>
          <textarea
            rows={1}
            cols={5}
            id="title"
            value={formData.title}
            onChange={(e) => handleInputChange("title", e.target.value)}
            placeholder="Insira o título do anúncio"
            required
          />
        </div>
        <div className="area">
          <span>Descreva o seu anúncio</span>
          <textarea
            id="description"
            value={formData.description}
            onChange={(e) => handleInputChange("description", e.target.value)}
            required
            rows={5}
            cols={50}
            placeholder="Insira o conteúdo do anúncio"
          />
        </div>
        <ImageInputList maxImages={8} onImagesChange={setImages} />
      </div>
      <div className="optional">
        <div className="instructor">
          <AvatarContainer
            avatar={avatar || default_avatar}
            edit={true}
            onAvatarChange={handleAvatarChange}
            id="instructor-avatar"
          />
          <div className="user-instructor">
            <input
              type="checkbox"
              id="instuctor-check"
              onChange={() =>
                instructorCheck === false
                  ? setInstructorCheck(true)
                  : setInstructorCheck(false)
              }
            />
            <span>Sou o instutor da atividade</span>
          </div>

          <div className="area area-2">
            <span>Nome do Instutor</span>
            <textarea
              rows={1}
              cols={4}
              value={formData.instructorName}
              placeholder="Insira o nome do instutor"
              onChange={(e) =>
                handleInputChange("instuctorName", e.target.value)
              }
            />
          </div>
          <div className="area area-2">
            <span>Email do Instutor</span>
            <textarea
              rows={1}
              cols={4}
              value={formData.instructorEmail}
              placeholder="Insira o email do instutor"
              onChange={(e) =>
                handleInputChange("instuctorEmail", e.target.value)
              }
            />
          </div>
          <div className="content-area">
            <div className="area area-2">
              <span>Telefone</span>
              <div className="number">
                <textarea
                  className="text line"
                  id="numcode"
                  maxLength={2}
                  rows={1}
                  cols={1}
                  value={formData.instructorNumcode}
                  placeholder="XX"
                  onChange={(e) =>
                    handleInputChange("instructorNumcode", e.target.value)
                  }
                />
                <textarea
                  className="text line"
                  id="number"
                  rows={1}
                  cols={2}
                  value={formData.instructorNumber}
                  placeholder="0000-0000"
                  onChange={(e) =>
                    handleInputChange("instructorNumber", e.target.value)
                  }
                />
              </div>
            </div>
            <div className="area">
              <span>Documento</span>
              <DocumentInput
                disabled={!edit}
                onChange={(field, value) => handleInputChange(field, value)}
              />
            </div>
          </div>
          <div className="area area-3">
            <span>Certificação na área de instrução</span>
            <textarea rows={2} cols={5} />
          </div>
        </div>
        <div className="local ">
          <div className="area area-local">
            <span className="title">Categoria</span>
            <select name="selector" id="">
              <option>Atividade em piscina</option>
              <option>Águas abertas</option>
            </select>
          </div>
        </div>

        <div className="checkbox-group">
          <span className="title">Nível do curso</span>
          <div className="input">
            <input type="checkbox" />
            <label>Curso iniciante</label>
          </div>
          <div className="input">
            <input type="checkbox" />
            <label>Curso Intermediário</label>{" "}
          </div>
          <div className="input">
            <input type="checkbox" />
            <label>Curso Avançado</label>
          </div>
        </div>
        <div className="content-area">
          <div className="area area-2">
            <span className="title">Número máximo de pessoas por evento</span>
            <input type="number" placeholder="insira o número de pessoas" />
          </div>
          <div className="area area-2">
            <span className="title">Tempo de aula (em minutos)</span>
            <input type="number" placeholder="insira o tempo de aula" />
          </div>
        </div>
        <div className="checkbox-group">
          <span className="title">Datas disponíveis</span>
          {daysOfWeek.map((day) => (
            <div key={day.value} className="input">
              <input
                type="checkbox"
                id={day.value}
                checked={selectedDays.includes(day.name)}
                onChange={() => handleDayChange(day.name)}
              />
              <label htmlFor={day.value}>{day.name}</label>
            </div>
          ))}
        </div>
        <span className="title">
          Dias selecionados: {selectedDays.join(", ")}
        </span>
      </div>
      <div className="terms">
        <input type="checkbox" />
        <label>
          Esse anúncio está de acordo com os termos de serviço para{" "}
          <Link
            to={"/seguro/anunciante"}
            className="link"
            onChange={() => {
              allow ? setAllow(true) : setAllow(false);
            }}
          >
            Seguro Aventura
          </Link>
        </label>
      </div>

      <div className="bottom">
        <button id="cancel" onClick={() => window.location.reload()}>
          Cancelar
        </button>
        <button id="create" onClick={saveAnnouncement} disabled={allow}>
          Salvar
        </button>
      </div>
    </div>
  );
};

export default Sports;
