import "./style.scss";

type Filds = {
  title?: string;
  textarea?: boolean;
  label?: string;
  type?: string;
  placeholder?: string;
  value?: string;
  id?: string;
  className?: string;
  classNameDiv?: string;
  style?: string;
  selector?: true;
  options?: string[];
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  checkbox?: boolean;
  languages?: { id: string; label: string }[];
  selectedLanguages?: string[];
};

const Form = ({ filds }: { filds: Filds[] }) => {
  let skipCount = 0; // Variável para controlar quantos campos devemos pular

  return (
    <form className="form">
      {filds.map((fild, index) => {
        if (skipCount > 0) {
          skipCount--; // Reduz o contador para pular o próximo campo
          return null; // Ignora o campo
        }

        // Verifica se o campo atual tem a classe 'field-groups' (grupo de 3 campos)
        if (fild.className === "field-groups") {
          const nextField = filds[index + 1];
          const nextNextField = filds[index + 2];

          if (nextField && nextNextField) {
            skipCount = 2; // Ignora os próximos 2 campos, pois já foram agrupados

            return (
              <div key={index} className="field-groups">
                <div className={fild.classNameDiv}>
                  <label>{fild.label}</label>
                  <input
                    type={fild.type}
                    placeholder={fild.placeholder}
                    id={fild.id}
                    disabled={fild.disabled}
                    value={fild.value}
                  />
                </div>
                <div className={fild.classNameDiv} style={nextField.style}>
                  <label>{nextField.label}</label>
                  <input
                    type={nextField.type}
                    placeholder={nextField.placeholder}
                    id={nextField.id}
                    disabled={nextField.disabled}
                    value={nextField.value}
                    onChange={nextField.onChange}
                  />
                </div>
                <div className={fild.classNameDiv} style={nextNextField.style}>
                  <label>{nextNextField.label}</label>
                  <input
                    type={nextNextField.type}
                    placeholder={nextNextField.placeholder}
                    id={nextNextField.id}
                    disabled={nextNextField.disabled}
                    value={nextNextField.value}
                    onChange={nextNextField.onChange}
                  />
                </div>
              </div>
            );
          }
        }

        // Verifica se o campo atual tem a classe 'field-group' (grupo de 2 campos)
        if (fild.className === "field-group") {
          const nextField = filds[index + 1];

          if (nextField) {
            skipCount = 1; // Ignora o próximo campo, pois já foi agrupado

            return (
              <div key={index} className="field-group">
                <div className={fild.classNameDiv}>
                  <label>{fild.label}</label>
                  <input
                    type={fild.type}
                    placeholder={fild.placeholder}
                    id={fild.id}
                    disabled={fild.disabled}
                    value={fild.value}
                    onChange={fild.onChange}
                  />
                </div>
                <div className={fild.classNameDiv}>
                  <label>{nextField.label}</label>
                  <input
                    type={nextField.type}
                    placeholder={nextField.placeholder}
                    id={nextField.id}
                    disabled={fild.disabled}
                    value={nextField.value}
                    onChange={nextField.onChange}
                  />
                </div>
              </div>
            );
          }
        }

        // Renderiza campos individuais, se não foram agrupados
        return (
          <div key={index} className={fild.classNameDiv}>
            {fild.title && <h3>{fild.title}</h3>}
            {fild.label && <label>{fild.label}</label>}
            {fild.textarea && (
              <textarea
                placeholder={fild.placeholder}
                id={fild.id}
                disabled={fild.disabled}
                value={fild.value}
                onChange={fild.onChange}
              />
            )}
            {fild.type && (
              <input
                type={fild.type}
                placeholder={fild.placeholder}
                id={fild.id}
                className={fild.className}
                disabled={fild.disabled}
                value={fild.value}
                onChange={fild.onChange}
              />
            )}
            {fild.selector && fild.options && (
              <div className={fild.className}>
                <select
                  value={fild.placeholder}
                  onChange={fild.onChange}
                  disabled={fild.disabled}
                >
                  <option>{fild.placeholder}</option>
                  {fild.options.map((option, optionIndex) => (
                    <option key={optionIndex} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            )}
            {/* Tratamento para checkbox de idiomas */}
            {fild.checkbox && !fild.disabled ? (
              <div className={fild.className}>
                {fild.languages?.map((language) => (
                  <div key={language.id} className="language-checkbox">
                    <input
                      type="checkbox"
                      id={language.id}
                      value={language.label}
                      checked={fild.selectedLanguages?.includes(language.label)}
                      onChange={fild.onChange}
                    />
                    <label htmlFor={language.id}>{language.label}</label>
                  </div>
                ))}
                <div className="language-checkbox">
                  <input
                    type="checkbox"
                    id="other"
                    value="Outro"
                    onChange={fild.onChange}
                  />
                  <label htmlFor="other" id="otherLabel">
                    <input id="otherInput" type="text" placeholder="Outro" />
                  </label>
                </div>
              </div>
            ) : (
              <div className="languages-display">
                <div className="languages-grid">
                  {fild.selectedLanguages?.map((language) => (
                    <div key={language} className="language-box">
                      {language}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        );
      })}
    </form>
  );
};

export default Form;
