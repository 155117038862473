import "./style.scss";
import React from "react";
import { BuscaForm } from "../../../components/busca-form/busca-form";
import TextSlider from "./textslider.jsx";
export function Introduction() {
  return (
    <>
      <section className="introduction">
        <TextSlider />
      </section>
      <section className="introduction-text">
        <h2>Encontre sua experiência perfeita para viver momentos inesquecíveis.</h2>
      </section>
    </>
  );
}
