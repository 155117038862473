// Adicione este import
import React, { useEffect, useState } from "react";
import "./style.scss";
import LogoMarAbertoColorida from "../../misc/logo-colorida.svg";
import LogoMarSocialColorida from "../../misc/marsocial-colorido.png";
import BurguerMenuIcon from "../../misc/icons/burguer.svg";
import { NavLink } from "react-router-dom";
import { MobileMenu } from "./mobile-menu/mobile-menu";
import LoginPopup from "../login-pop-up/loginPopup";
import { useAuth } from "../../services/firebase/authContext";
import MenuPopup from "../user-menu/menuPopup";
import Language from "../selector/language";
import confirmationPopup from "./confirmPopup";

const NavBar: React.FC = () => {
  //Menus de login e página
  const [openMobile, setOpenMobile] = useState(false);
  const [marAbertoLastActive, setMarAbertoLastActive] = useState(true);
  const [marSocialLastActive, setMarSocialLastActive] = useState(false);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    const storedIsLoggedIn = localStorage.getItem("isLoggedIn");
    return storedIsLoggedIn ? JSON.parse(storedIsLoggedIn) : false;
  });
  //Chamada de variável global de Hook UseAuth
  const { userEmail } = useAuth();
  const {userData} = useAuth();
  

  useEffect(() => {
    localStorage.setItem("isLoggedIn", JSON.stringify(isLoggedIn));
  }, [isLoggedIn]);

  const openMenu = () => {
    setOpenMobile(true);
  };

  const marAbertoLastHandler = () => {
    setMarAbertoLastActive(true);
    setMarSocialLastActive(false);
  };
  const marSocialLastHandler = () => {
    setMarSocialLastActive(true);
    setMarAbertoLastActive(false);
  };
  const handleLoginButtonClick = () => {
    setShowLoginPopup(true);
  };
  const handleCloseLoginPopup = () => {
    setShowLoginPopup(false);
  };

  // Adicione este manipulador de eventos
  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem("isLoggedIn");
  };

  return (
    <nav>
      <div className="logo-search-container">
        <NavLink
          className={`logo ${marAbertoLastActive ? "active" : ""}`}
          to="/"
          onClick={marAbertoLastHandler}
        >
          <img
            className="logo-mar-aberto"
            draggable="false"
            src={LogoMarAbertoColorida}
            alt="logotipo mar aberto"
          />
        </NavLink>
        <NavLink
          className={`logo ${marSocialLastActive ? "active" : ""}`}
          to="/marsocial"
          onClick={marSocialLastHandler}
        >
          <img
            className="logo-mar-aberto"
            draggable="false"
            src={LogoMarSocialColorida}
            alt="logotipo mar social"
          />
        </NavLink>
      </div>
      <div className="menu-container">
        <div className="language-selector">
          <Language />
        </div>
        <div className="menu-items-container">
          <div className="sign">
            <a href="/buscar-experiencias">Experiências</a>
            <a href="/ajuda">Ajuda</a>
          </div>

          {/* Informações do canto superior direito -- Login e perfil do usuário */}
          <div>
            <ul className="sign">
              {userEmail ? (
                <MenuPopup />
              ) : (
                <button
                  className="sign-button"
                  onClick={handleLoginButtonClick}
                >
                  Login
                </button>
              )}
            </ul>
            {showLoginPopup && (
              <LoginPopup
                onClose={handleCloseLoginPopup}
                setUser={setIsLoggedIn}
              />
            )}
          </div>
        </div>
      </div>
      <div className="burguer-menu" onClick={openMenu}>
        <img src={BurguerMenuIcon} alt="" />
      </div>
      <MobileMenu
        open={openMobile}
        setOpen={setOpenMobile}
        setMarAbertoLastActive={setMarAbertoLastActive}
        setMarSocialLastActive={setMarSocialLastActive}
      />
    </nav>
  );
};
export default NavBar;
