import {contact} from "../../services/data/tokens"
import { useState } from "react";
import './style.scss';
const email_form = contact.email;

const Email = () => {
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (event: { preventDefault: () => void; target: any }) => {
    event.preventDefault();
    setSubmitted(true);
    const form = event.target;
    form.action = `https://formsubmit.co/${email_form}`;
    form.method = "POST";
    form.submit();
  };
  return (
    <div className="box">
      <div id="form" className="contact-form">
        <div className="email-form">
          <h2>Envie um email</h2>
          <form onSubmit={handleSubmit}>
            <div className="input-wrapper">
              <p>Nome Completo</p>
              <input type="text" name="nome" id="" required />
            </div>
            <div className="input-wrapper">
              <p>Telefone de contato</p>
              <input type="text" name="tel" id="" required />
            </div>
            <div className="input-wrapper">
              <p>E-mail para contato</p>
              <input type="email" name="email" id="" required />
            </div>
            <div className="input-wrapper">
              <p>
                Envie sua dúvida ou sujestão
              </p>
              <textarea name="detalhes" required id=""></textarea>
            </div>
            <input type="hidden" name="_captcha" value="false"></input>
            <input
              type="hidden"
              name="_next"
              value="http://localhost:3000/ajuda"
            ></input>
            <button type="submit">Enviar</button>
          </form>
        </div>
      </div>
    </div>

  );
};
export default Email