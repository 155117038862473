import { useState } from "react";
import './style.scss';
import defaultIcon from '../../misc/icons/arrow.png';

interface CustomSelectProps {
  items: { name: string; icon?: string }[];
  onSelect: (index: number, name: string | undefined) => void;
  defaultText: string;
}

const CustomSelect = ({ items, onSelect, defaultText }: CustomSelectProps) => {
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const handleItemClick = (index: number) => {
    setSelectedIndex(index);
    const selectedItemName = items[index]?.name;
    onSelect(index, selectedItemName);
  };

  return (
    <div className="custom-select list">
      <div className="selected-option">
        <span>
          {selectedIndex !== null ? items[selectedIndex].name : defaultText}
        </span>
        {selectedIndex !== null && items[selectedIndex].icon && (
          <img src={items[selectedIndex].icon} alt="Selected Icon" className="icon" />
        )}
      </div>
      <ul className="options-list">
        {items.map((item, index) => (
          <li
            key={index}
            className={`option ${selectedIndex === index ? "selected" : ""}`}
            onClick={() => handleItemClick(index)}
          >
            <span>{item.name}</span>
            {item.icon && <img src={item.icon} alt={`${item.name} icon`} className="option-icon" />}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CustomSelect;
