import { IAvaliacao } from "../../../models/avaliacao";
import "./avaliacao.scss";
import PersonaPerfil from "../../../misc/icons/persona-perfil.png";

interface IAvaliacaoProps {
  comentario: IAvaliacao;
}

export function Avaliacao(props: IAvaliacaoProps) {
  const comentario = props.comentario;
  return (
    <div className="avaliacao medium-grey">
      <div className="header">
        <img
          src={PersonaPerfil}
          alt=""
        />
        <div className="info">
          <span className="black">{comentario.nomeAvaliador}</span>
          <span className="small">{comentario.dataAvaliacao}</span>
        </div>
      </div>
      <div className="descricao-container">
        <div className="light">{comentario.comentario}</div>
      </div>
    </div>
  );
}
