import React from 'react';
import './style.scss';
import {contact} from "../../services/data/tokens"
const SecurityPolicy: React.FC = () => {
  const email = contact.email
  return (
    <div className="security-policy">
      <h1>TERMO DE ADESÃO E CONDIÇÕES DE USO</h1>

      <section>
        <p>
          Todo usuário, proprietários e contratantes são responsáveis pelas informações inseridas no site da Mar Aberto, pela veracidade dos dados pessoais. A Mar Aberto reserva o direito de conectar e auxiliar ambas as partes como intermediadora de pagamento e registro de contratação dos serviços publicados.
        </p>
        <p>Ambos são responsáveis pela segurança da atividade, quem oferece o serviço em condições perfeitas para uso e o que utilizar os equipamentos, com devido respeito e cuidados, respeitando as regras de cada prática.</p>
        <p>Os pagamentos serão efetuados através do PagSeguro com as opções de cartões de crédito, débito e Pix.</p>
        <p>Os proprietários receberão os pagamentos conforme os dias necessários para o processamento disponibilizado pela PagSeguro, sendo somente após a experiência ser confirmada, ou seja, check-in autorizado, o pagamento e data serão disponibilizados.</p>
        <p>O ACEITE para uso da plataforma inclui concordar com termos de uso da plataforma e com meio de pagamento da PagSeguro/PagBank. Ainda, a Mar Aberto necessita até 3 dias úteis, após receber dessa operadora, para então, transmitir esses valores aos proprietários ou valores provenientes de cancelamento.</p>
        <p>A Mar Aberto é uma plataforma online de anúncios de vendas de experiências náuticas diversas. A Mar Aberto atua como mero intermediador de negócios advindos de diversos anunciantes, não agindo, em hipótese alguma, como agente de turismo, nem tendo qualquer vínculo como empregado, prestador de serviços, consultor, intermediário ou participante nem dos usuários, nem dos anunciantes.</p>
        <p className="red">A Mar Aberto não é empresa de turismo. Por isso, não se vincula obrigatoriamente a quaisquer diretrizes da Embratur, nem do Ministério do Turismo.</p>
        <p>A Mar Aberto não participa do contrato de locação realizado entre o anunciante e o usuário, mas apenas e tão somente assessora os donos das embarcações a concretizarem tais contratos. A Mar Aberto simplesmente disponibiliza soluções de pagamento, e facilita ao usuário a opção de um Seguro entre usuários e anunciantes, colocando as partes interessadas em contato para realizarem o contrato de locação entre si, onde possuem suas próprias regras de atendimento ao cliente.</p>
        <p>Para sua utilização, é necessária a aceitação expressa e irrestrita ao presente TERMO DE ADESÃO E CONDIÇÕES DE USO, a seguir descrito, em conformidade com a legislação brasileira e internacional vigente.</p>
      </section>
      <h2>I. Condições Gerais</h2>
      <ul>
        <li>Os anunciantes da Mar Aberto poderão utilizar as plataformas online apenas para finalidades lícitas, não podendo publicar, enviar, distribuir ou divulgar conteúdo ou informação de caráter difamatório, obsceno ou ilícito, inclusive informações de propriedade exclusiva pertencentes a outras pessoas ou empresas, bem como marcas registradas ou informações protegidas por direitos autorais, sem a expressa autorização do detentor desses direitos. A Mar Aberto não é obrigada a monitorar, nem a restringir o acesso a qualquer área das plataformas online onde usuários e prestadores de serviço transmitem e trocam informações entre si, mas poderá fazê-lo para garantir a boa qualidade nos serviços prestados.</li>
        <li>Os textos dos anúncios não podem conter informações sobre o contato do anunciante, tais como: e-mail, números de telefone, links ou sites externos. A Mar Aberto reserva-se o direito a intervir nos textos dos anúncios para a retirada desse tipo de conteúdo. As fotos enviadas pelos proprietários que tiverem o nome da embarcação visível poderão ser alteradas para manter o sigilo do nome divulgado antes da confirmação da reserva.</li>
        <li>A Mar Aberto não poderá ser responsabilizada, em hipótese alguma, em função de qualquer relação entre o usuário e o anunciante, seja ela direta ou indireta. Se o usuário, anunciante, ou terceiro demandar reclamação ou ação legal, todos os envolvidos eximirão a Mar Aberto de toda e qualquer responsabilidade, assim como seus funcionários, agentes, operários, representantes e procuradores.</li>
        <li>Em hipótese alguma, fica caracterizada relação de consumo entre a Mar Aberto e o anunciante, visto que esse trata de relação estritamente comercial, entre prestadores de serviços e fornecedores, independentemente se pessoas jurídicas ou não, nos termos do Código Civil, do sistema civilista e das regras do Direito Civil.</li>
        <li>A Mar Aberto não se responsabiliza por obrigações tributárias, trabalhistas, penais, civis, administrativas, ambientais, nem quaisquer outras, pertinentes às atividades realizadas por usuários e anunciantes, isoladamente, entre si, ou em conjunto, de modo que esses a eximirão ou a ressarcirão pelos prejuízos assim que cientificadas da respectiva ocorrência.</li>
        <li>Para anunciar embarcações, basta o dono ou operador preencher completa e corretamente os dados do formulário do cadastro e a(s) embarcação(ões) ficará(ão) disponibilizada(s) para aluguel no sistema. Caso surja algum interessado na embarcação anunciada, a relação contratual será estabelecida entre o anunciante, e o usuário que adquirir o tíquete para embarcação em questão, por meio de um contrato de locação, no qual a Mar Aberto não figurará, nem será mencionada em nenhum momento.</li>
        <li>O anunciante compromete-se, desde já, a utilizar, dar ACEITE ou preencher, obrigatoriamente, todos os modelos e formulários disponibilizados pela Mar Aberto, especialmente o modelo de contrato de locação que deverá ser assinado em conjunto com os compradores dos tíquetes. Nesse caso, os anunciantes não poderão fazer alterações, nem alterar o preço final estabelecido pela Mar Aberto.</li>
        <li>Todos os riscos derivados da utilização da Mar Aberto são do usuário e do anunciante. Em nenhum caso a Mar Aberto será responsabilizada por qualquer dano direto, indireto, incidental, especial ou como consequência de quaisquer fatos resultantes do uso das plataformas online ou da inabilidade de uso destas, ou ainda por quaisquer informações, produtos ou serviços obtidos através dele ou em decorrência do seu uso.</li>
        <li>Usuários e anunciantes indenizarão a Mar Aberto, seus administradores, colaboradores, representantes e empregados por qualquer demanda promovida por outros usuários, anunciantes ou terceiros, decorrentes de suas atividades ilegais ou pela violação de qualquer lei ou direitos de terceiros, sendo de responsabilidade do respectivo usuário ou anunciante a reparação por perdas e danos, juros de mora, correção monetária e honorários advocatícios.</li>
        <li>Todas as transações financeiras na Mar Aberto são realizadas através do PagSeguro/Pag/Bank. Soluções adicionais de pagamento, poderão ser incorporadas sempre levando em consideração os mesmos rigorosos critérios na avaliação da segurança e da qualidade da operação oferecida pela operadora de pagamentos em questão.</li>
        <li>Os proprietários, administradores e funcionários da Mar Aberto poderão, a seu exclusivo critério e em qualquer tempo, modificar as plataformas online, bem como limitar, cancelar ou suspender seu uso ou acesso, e alterar este Termo de Adesão e Condições de Uso ou os preços dos serviços e produtos institucionais das plataformas online, como comissões, unilateralmente e a qualquer momento, sem atingir, entretanto, as operações já concluídas.</li>
        <li>A Mar Aberto exige dos seus anunciantes o mais alto nível de atendimento, compliance e profissionalismo. Entretanto, ela não se responsabiliza pela qualidade dos serviços, informações e facilidades contidas nos anúncios, nem tampouco por qualquer ação ou omissão de quaisquer das partes por informações, anúncios, fotos, vídeos ou outros materiais veiculados com imprecisão, erro, fraude, inexatidão ou divergência.</li>
        <li>Em nenhum caso os proprietários, administradores e funcionários da Mar Aberto serão responsabilizados pelos anunciantes por quaisquer danos, incluindo lucros cessantes, interrupção de negócio, não prestação de serviços ou perda de informação que resultem do uso ou da incapacidade de usar as embarcações anunciadas, nem tampouco dano, material ou moral, que o proprietário das embarcações venha sofrer, decorrentes dos charters realizados, tíquetes vendidos ou serviços prestados.</li>
      </ul>

      <h2>II. Política de Compliance</h2>
      <section>
        <p>O anunciante deve ser o mais transparente possível em todas as informações que prestar, comprovando ser o verdadeiro dono ou alguém que tenha condições legais de alugar em nome do dono, respeitando assim os seus direitos sob a propriedade dos equipamentos e experiências:</p>
        <ul>
          <li>do barco, não sendo aceitas negociações com marinheiros que dizem alugar em nome do proprietário, salvo quando a Mar Aberto entender pertinente tal tipo de contratação.</li>
          <li>O anunciante da experiência ou embarcações serão responsabilizados caso estejam atuando mesmo sofrendo algum embargo das autoridades brasileiras, seja ele temporário ou permanente, nem que não estejam em dia com as obrigações perante os órgãos reguladores e fiscalizatórios brasileiros.</li>
          <li>O anunciante assume todos os riscos inerentes à embarcação e aos usuários da plataforma que transportar, ou seja, tanto o risco de integridade e preservação da embarcação, quanto o risco em relação à integridade física dos usuários e de terceiros. Por mera liberalidade, a Mar Aberto pode auxiliar o anunciante na avaliação e escolha dos produtos de transferência de risco, oferecidos pelas principais seguradoras do Brasil, ou exigidos em algum momento por norma nacional, ou decisão isolada de uma delegacia ou capitania dos portos do Brasil. Contudo, não é obrigada a oferecer soluções de seguro para os proprietários, nem a proporcionar algum produto nesse sentido.</li>
          <li>Não serão aceitas locações de atividades ou embarcações para um número de pessoas maior do que o limite superior expressado no registro do equipamento ou embarcação.</li>
          <li>O anunciante não poderá negar conhecimentos significativos para o bom e seguro uso da atividade ou embarcação, como falhas mecânicas, ausência de peças e equipamentos a bordo, barulhos que indiquem colapso de equipamentos ou colapso da integridade estrutural do casco da embarcação, devendo inclusive assegurar que todos os itens exigidos pela marinha deverão estar de acordo com as normas da autoridade brasileira. Por isso, o preenchimento do check list constante do Anexo I é condição obrigatória antes da saída da embarcação.</li>
          <li>O anunciante é responsável para que as atividades ou embarcações respeitem a lista de equipamentos mínimos de segurança publicado pela autoridade marítima brasileira.</li>
          <li>O anunciante é responsável por atividades ou embarcações compradas para esconder recursos financeiros de origem ilegal ou transportar ou esconder valores não declarados ou para a prática de qualquer ilicitude, prevista na legislação brasileira.</li>
          <li>O anunciante é responsável por locações de equipamentos ou embarcação com marinheiros que não saibam respeitar os limites do bom convívio com os usuários, adotando posturas invasivas, ofensivas ou agressivas.</li>
          <li>O anunciante é responsável por equipamentos ou embarcações que sejam abastecidas com combustível de origem duvidosa, pondo em risco a integridade física dos usuários.</li>
          <li>Serão responsabilizados juntamente com anunciante os marinheiros indicados pelos proprietários que tenham conduta sabidamente indiligente, negligente, imperita ou despreocupada com a segurança dos usuários.</li>
          <li>O anunciante é responsável por equipamentos ou embarcações para que estejam limpas, organizadas e preparadas para bem receber os usuários.</li>
          <li>O anunciante se responsabiliza pelo embarque de usuários em locais inadequados, ilegais ou que ponham em risco a integridade física dos usuários em risco.</li>
          <li>Os anunciantes deverão envidar todos os esforços a seu alcance para atender da melhor maneira os usuários indicados para o aluguel de suas atividades ou embarcações.</li>
        </ul>
      </section>
      <h2>III. Tratamento de informações</h2>
      <section>
        <ul>
          <li>Não é permitida a utilização de nenhum dispositivo, software ou outro recurso que venha a interferir nas atividades e operações do site da Mar Aberto, bem como nos anúncios, descrições, contas ou seus bancos de dados. Qualquer intromissão, tentativa de, ou atividade que viole ou contrarie as leis de direito de propriedade intelectual e/ou as proibições estipuladas nestes termos, tornarão o responsável passível das ações legais pertinentes, sendo ainda responsável pelas indenizações por eventuais danos causados.</li>
          <li>Toda informação ou dados pessoais inseridos pelo usuário no site é armazenada em servidores com segurança. A Mar Aberto se compromete em adotar as medidas necessárias para manter o sigilo e a segurança das informações, conforme a Lei de Proteção de Dados, ficando estabelecido, entretanto, que não responderá por quaisquer prejuízos ocasionados pela violação das medidas necessárias de segurança e sigilo por terceiros que utilizem as redes públicas ou a internet, subvertendo os sistemas de segurança para acessar as informações de usuários.</li>
          <li>Eventuais dúvidas sobre a proteção e sigilo dos dados pessoais ou para obter maiores informações consulte nossa de política de privacidade.</li>
        </ul>
      </section>
      <section>
        <h2>IV. Reclamações</h2>
        <ul>
          <li>Reclamações do proprietário</li>
          <ul>
            <li>Antes do desembarque dos usuários, o dono e o marinheiro devem conferir o estado da embarcação, e, se foi o combinado, fazer as contas das despesas com combustível, marinas, ancoradouros, restaurantes, marinheiros, alimentação e bebidas a bordo, dentre outros. Se entender que precisa solicitar algum tipo de reembolso ou ressarcimento, deve tentar chegar a um acordo diretamente com o usuário.</li>
            <li>Se o proprietário percebe algum problema após o desembarque dos usuários, Mar Aberto oferece um sistema de Disputas para atender essas reclamações. O prazo para abrir uma Disputa é de até 3 dias (72 horas) após o desembarque dos usuários. Depois desse prazo, o comportamento dos usuários, seu cuidado com o barco e consumação de produtos e serviços a bordo, dentre outros, serão considerados satisfatórios e não será mais possível fazer reclamação sobre o mesmo charter, viagem ou passeio.</li>
            <li>Para iniciar uma Disputa, o dono ou operador da embarcação deve enviar um e-mail para {email}, com todas as informações disponíveis sobre o problema, número da reserva, nome do usuário e titular da compra, descrição da situação, fotos e vídeos, dentre outros. Assim que a reclamação for enviada, a Mar Aberto será o “mediador” entre o dono e o usuário, atendendo aos argumentos das duas partes. Após análise, A Mar Aberto tomará uma decisão e comunicará o resultado às partes envolvidas, solicitando um ressarcimento em dinheiro ao usuário (anexando orçamentos) ou não solicitando indenização nenhuma, segundo a decisão, que é inapelável e tomada atendendo aos preceitos de imparcialidade, exaustivo processamento das informações disponíveis, argumentos, senso comum e justiça.</li>
          </ul>
        </ul>
      </section>
      <section>
        <h2>V. Comissões</h2>
        <ul>
          <li>As comissões pagas à Mar Aberto, resultam do preço final passado aos usuários no contrato de locação, entabulado entre os proprietários dos barcos, e os compradores dos tíquetes, não sendo retirada do valor do aluguel devido aos anunciantes.</li>
          <li>Tampouco serão descontados do valor do aluguel devido aos anunciantes, as taxas cobradas pela utilização de serviços de pagamento online que também serão incluídas, conforme o caso, no preço do contrato de locação a ser firmado entre proprietários dos barcos e os compradores dos tíquetes.</li>
          <li>Em razão do comissionamento da Mar Aberto, e das taxas cobradas pela utilização dos serviços de pagamento online, não impactarem no valor a ser recebido pelos anunciantes, a Mar Aberto é livre para alterar os valores e percentuais como bem entender a título de comissionamento, independentemente da anuência ou aceitação dos anunciantes, assim como para fixar o preço final do contrato de locação, que contemplará, evidentemente, o valor do aluguel informado pelo anunciante à Mar Aberto.</li>
          <li>Os anunciantes não podem se negar a assinatura do contrato de locação com os compradores de atividades sob qualquer pretexto, principalmente sob a justificativa de discordarem dos percentuais de comissionamento praticados pela Mar Aberto, ou do valor final do contrato de locação fixado pela Mar Aberto.</li>
          <li>Os donos e operadores das embarcações anunciadas tem duas maneiras de receber o dinheiro:
            <ul>
              <li>Mediante depósito na conta de banco informada no cadastro do anunciante, após o desconto das respectivas taxas de transferência, conforme o caso.</li>
              <li>A Mar Aberto se reserva no direito de realizar a transferência do valor devido ao proprietário da atividade no prazo máximo de 5 dias úteis, para pagamentos via pix, contados após a realização do passeio, tendo-se em vista o tempo médio gasto pelos sistemas de pagamento online realizar as transações.</li>
              <li>A Mar Aberto se reserva no direito de realizar a transferência do valor pago em cartão de crédito devido ao proprietário da atividade no prazo máximo de 3 dias úteis após o recebimento da empresa intermediadora do meio de pagamentos PagSeguro/PagBank, contados após a realização do passeio, tendo-se em vista o tempo médio gasto pelos sistemas de pagamento online realizar as transações.</li>
            </ul>
          </li>
        </ul>
      </section>
      <section>
        <h2>VI. Direitos autorais</h2>
        <ul>
          <li>As informações, textos, imagens, formulários, documentos, arquivos e conteúdos institucionais contidos nas plataformas online pertencem aos proprietários da Mar Aberto e são protegidos por leis nacionais e internacionais. Não podem ser copiados, reproduzidos, modificados, publicados, atualizados, postados, transmitidos ou distribuídos de qualquer maneira sem autorização prévia e por escrito da Mar Aberto.</li>
          <li>Todo conteúdo, material, fotos, vídeos, informação, artigos, imagens e/ou outras comunicações que forem transmitidas, enviadas ou publicadas na Mar Aberto são públicas e não confidenciais, e qualquer violação aos direitos dos anunciantes ou dos usuários não será de responsabilidade da Mar Aberto. Nem anunciante, nem usuário podem copiar, modificar, alugar, arrendar, emprestar, vender, ceder, transferir, distribuir, executar, exibir, licenciar, sublicenciar, distribuir, transmitir, reproduzir, publicar, criar trabalho derivado, colocar e/ou usar em outra página da Internet, transferir ou vender qualquer material da Mar Aberto, tais como layout, marcas, logotipos, produtos, sistemas, denominações de serviços e outros materiais, incluindo programas, bases de dados, imagens, arquivos, materiais de qualquer outra espécie e que têm contratualmente autorizadas as suas veiculações em suas plataformas online, informação, software, lista de usuários, produtos ou serviços obtidos na Mar Aberto, assim como os relacionados à sua aparência e função, e a todos os dados, informações, textos, gráficos, imagens, materiais de áudio ou vídeo, fotografias, desenhos, marcas comerciais, nomes comerciais, endereços eletrônicos, que formam parte das plataformas online, que nele aparecem ou que por ele são disponibilizados, tanto de propriedade da Mar Aberto, quanto de seus licenciantes ou editoras de terceiros, para uso comercial, assim como realizar engenharia reversa, montagem reversa, criar trabalhos derivados e/ou baseados em qualquer código-fonte da Mar Aberto ou no conteúdo da Mar Aberto nem dedicar-se, de outra forma, à tentativa de descobrir qualquer código-fonte da Mar Aberto, estando tais conteúdos protegidos por direitos autorais, direitos de propriedade industrial e os demais direitos cabíveis ao caso.</li>
          <li>As fotos e imagens institucionais da Mar Aberto são publicadas para fins de visualização, e, salvo autorização expressa por escrito, não podem ser gravadas ou baixadas via download. A reprodução ou armazenamento dos conteúdos institucionais das plataformas online sujeitará os infratores às penas da lei.</li>
          <li>O nome Mar Aberto, seu logotipo e domínios de Internet, bem como todos os elementos da plataforma web e bases de dados desenvolvidas e utilizadas aqui, constituem marcas registradas e propriedades intelectuais privadas e todos os direitos decorrentes de seu registro são assegurados por Lei.</li>
          <li>Toda e qualquer violação de direitos autorais deverá ser notificada à Mar Aberto e acompanhada dos documentos e informações que confirmam a autoria. A notificação poderá ser enviada pelos canais de comunicação oficiais.</li>
          <li>O acesso à Mar Aberto é proibido em territórios onde o conteúdo seja considerado ilegal. Aqueles que optarem por acessar as plataformas online a partir de localidades onde o acesso é proibido, o farão por iniciativa própria e serão responsáveis pelo cumprimento das leis locais aplicáveis.</li>
          <li>O uso indevido de materiais protegidos por propriedade intelectual (direito autoral, marcas comerciais, patentes etc.) apresentados nas plataformas online da Mar Aberto será caracterizado como infração da legislação pertinente, sujeitando o infrator às ações judiciais cabíveis e dando ensejo à respectiva indenização aos prejudicados, seja à Mar Aberto ou a terceiros, sem prejuízo de perdas e danos e honorários advocatícios.</li>
        </ul>
      </section>
      <section>
        <h2>VII. Contato e suporte</h2>
        <ul>
          <li className="red">Existem duas maneiras de solicitar ajuda, enviar sugestões e fazer consultas na Mar Aberto:
            <ul>
              <li>Contato – no menu do rodapé do site tem o botão “Contato” com um formulário de atendimento, preencha seus dados, envie uma mensagem e aguarde até 24 horas para receber uma resposta no seu e-mail.</li>
              <li>Telefone: contato pelo telefone listado no rodapé da página inicial no site da Mar Aberto.</li>
            </ul>
          </li>
        </ul>
      </section>
      <section>
        <h2>VIII. Foro de eleição</h2>
        <p>As partes, de comum acordo, elegem o foro da comarca de Porto Belo – SC para nele resolver qualquer divergência, litígio ou controvérsia decorrente do presente Termo de Adesão e Condições de Uso, de sua execução ou liquidação, ou que com ele se relacione.
          Diante desses termos, o ANUNCIANTE e USUÁRIO aceitam expressa e irrestritamente o Termo de Adesão e Condições de Uso aqui descrito.</p>
      </section>
      <p className="end" />
    </div>
  );
};

export default SecurityPolicy;
