import './style.scss'
import React from 'react';
import default_avatar from '../../misc/icons/default-user.png';

interface AvatarContainerProps {
  avatar: string;
  edit: boolean;
  onAvatarChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  id: string
}

const AvatarContainer: React.FC<AvatarContainerProps> = ({ avatar, edit, onAvatarChange, id }) => {
  return (
    <div className="avatar-container" id={id}>
      <img
        className="avatar"
        src={avatar || default_avatar}
      />
      {edit ?
        <div className="change-avatar">
          <label htmlFor="file-upload" className="upload-label">
            <i className="fas fa-camera"></i>
          </label>
          {/* Seletor de arquivos para avatar */}
          <input
            id="file-upload"
            type="file"
            accept="image/*"
            onChange={onAvatarChange}
          />
        </div>
        : <></>}
      <h3 id='instructor-label'>Imagem do instrutor</h3>
    </div>
  );
};

export default AvatarContainer;
