import React from 'react';
import './loginPopup.scss'
import { getAuth, signOut } from '@firebase/auth';
import { useAuth } from '../../services/firebase/authContext';
import { useNavigate } from 'react-router-dom';

const GoogleSignOut: React.FC = () => {
    const { setUserName, setUserEmail, setUserImage } = useAuth();  // Acessando o AuthContext
    const navigate = useNavigate();
    // Função de Logoff Google
    const handleGoogleSignOut = () => {
        const auth = getAuth();
        signOut(auth)
            .then(() => {
                // Limpeza de variáveis em AuthContext
                setUserName(null);
                setUserEmail(null);
                setUserImage(null);
                navigate("/");

            })
            .catch((error) => {
                console.error('Error during sign-out:', error);
            });
    };

    return <button className='sign-buttom' id="google-signout" onClick={handleGoogleSignOut}>Logoff</button>
};

export default GoogleSignOut;
